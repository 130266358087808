interface TagTextProps {
  children: React.ReactNode;
}

const TagText = ({ children }: TagTextProps) => {
  return (
    <span className="text-xs font-bold bg-primary text-white rounded-lg py-1 px-2">
      {children}
    </span>
  );
};

export default TagText;
