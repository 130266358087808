import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addDataToModel,
  addNewDataSpacetoModel,
  deleteDataSpacetoModel,
  getEspacioModel,
  setDataSpaceToModel,
} from "../../store/espacioSlice";
import { todayDay } from "../../constans/utils";
import { ToastContainer, toast } from "react-toastify";

import SchemaInputFile from "../../components/FormsJson/SchemaInputFile";
import SchemaInput from "../../components/FormsJson/SchemaInput";
import Button from "../../components/Button";

import { EspacioStateSpaces } from "../../store/dto/espacio.dto";
import dimensions from "../../assets/json/dimensions.json";
import adjunto from "../../assets/json/proyectos/adjunto.json";
import adjuntoExcel from "../../assets/json/proyectos/adjuntoExcel.json";
import nombre from "../../assets/json/espacios/nombre.json";
import {
  useSetFiletoDesarrolloMutation,
  useSetNewEspacioMutation,
} from "../../store/morhanaApi";
import SchemaArrayObjects from "../../components/FormsJson/SchemaArrayObjects";
import { Link } from "react-router-dom";

const EspaciosForm = () => {
  const [viewForm, setViewForm] = useState(true);
  const [file, setFile] = useState<File | Blob>();
  const localModel: EspacioStateSpaces = useSelector(getEspacioModel);
  const dispatch = useDispatch();

  const setExcelFile = (_nameForm: string, inputValue: File | any) => {
    setFile(inputValue);
  };

  const [fetcher, { isLoading, isError, isSuccess: fileWellReaded }] =
    useSetFiletoDesarrolloMutation();
  const [newEspacioFetcher, { isLoading: isNewEspacioLoading, isSuccess }] =
    useSetNewEspacioMutation();

  useEffect(() => {
    if (isError) {
      toast.error(
        "Ocurrió un error el enviar el archivo excel, verifica el formato.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [isError]);

  useEffect(() => {
    if (fileWellReaded) {
      toast.success("Se guardó la información con éxito, verificalo.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setFile(undefined);
    }
  }, [fileWellReaded]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Nuevo registro dado de alta con éxito", {
        position: toast.POSITION.TOP_CENTER,
      });
      dataSetterHandler("medidas_espacios", []);
      dataSetterHandler("nombre", "");
      dataSetterHandler("plano", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (localModel.plano) {
      if (!localModel.plano?.name?.includes(".dwg")) {
        toast.error("Verifica el formato del archivo .dwg", {
          position: toast.POSITION.TOP_CENTER,
        });
        dataSetterHandler("plano", null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localModel.plano]);

  const dataSetterHandler = useCallback(
    (key: string, inputValue: File | any) => {
      dispatch(
        addDataToModel({
          key,
          data: inputValue,
        })
      );
    },
    [dispatch]
  );
  const FormObjectSpacesHandler = (key: string, value: String, i: number) => {
    dispatch(
      setDataSpaceToModel({
        key: `[${i}][${key}]`,
        data: value,
      })
    );
  };
  const addNewObjectModelToSpace = () => {
    dispatch(addNewDataSpacetoModel());
  };
  const deleteObjectModelToSpace = (index: number) => {
    dispatch(deleteDataSpacetoModel(index));
  };

  const submitHandler = () => {
    if (!localModel.nombre || localModel.medidas_espacios.length === 0) {
      return toast.error(
        "Es necesario ingresar el nombre del Desarrollo y mínimo un prototipo/modelo que pertenezca a ese desarrollo.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
    const payload = {
      nombre: localModel.nombre,
      fk_prototipos: localModel.medidas_espacios,
    };
    newEspacioFetcher(payload);
  };

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <ToastContainer />
      <Link to="/espacios" className="mr-auto color-red-600">
        Regresar
      </Link>
      <div className="flex flex-row items-center mb-4">
        <h1 className="mb-6 text-2xl mr-auto font-bold">
          Solucionador de espacios
        </h1>
        <div className="ml-auto mr-2">Fecha de registro</div>
        <div className="custom-btn bg-white text-gray-900">{todayDay}</div>
      </div>
      <div className="bg-white px-6 py-4 rounded-lg mt-8">
        <div className="flex flex-col">
          {viewForm && (
            <div className="grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4">
              <SchemaInput
                schema={nombre}
                nameForm={"nombre"}
                modelSetter={dataSetterHandler}
                value={localModel.nombre}
              />
            </div>
          )}
          <div className="inline-flex w-full justify-center" role="group">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 bg-gray-200 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500"
              onClick={() => setViewForm(true)}
            >
              Formulario
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 bg-gray-200 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500"
              onClick={() => setViewForm(false)}
            >
              Archivo excel
            </button>
          </div>
          {viewForm ? (
            <>
              <div className="mt-2">
                <SchemaArrayObjects
                  schema={dimensions}
                  newElementToArray={addNewObjectModelToSpace}
                  customModelValues={localModel.medidas_espacios}
                  customModelSetter={FormObjectSpacesHandler}
                  deleteElementofArray={deleteObjectModelToSpace}
                  buttonMessage="Agregar nuevo prototipo"
                  schemaFormClasses="grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4 w-full md:border-l-4 md:border-gray-300 md:pl-2"
                />
              </div>
            </>
          ) : (
            <>
              <div
                className="my-2 p-4 text-sm text-blue-800 rounded-lg bg-blue-50"
                role="alert"
              >
                <span className="font-medium">
                  Usa un archivo <strong>excel</strong> con el formato correcto
                  para subir la información
                </span>
              </div>
              <div className="my-8 flex flex-col">
                <SchemaInputFile
                  schema={adjuntoExcel}
                  nameForm={"excel"}
                  modelSetter={setExcelFile}
                  value={file}
                />
                <Button
                  text="Leer excel"
                  classes="bg-gray-800 text-white w-64 mx-auto mt-4 mb-12"
                  disabled={isLoading}
                  onClick={() => file && fetcher(file)}
                />
              </div>
            </>
          )}
        </div>
        {viewForm && (
          <>
            <div className="mt-8">
              <SchemaInputFile
                schema={adjunto}
                nameForm={"plano"}
                modelSetter={dataSetterHandler}
                value={localModel.plano}
              />
            </div>
            <div className="text-center mt-8 mb-4">
              <Button
                text="Crear nuevo desarrollo y prototipos"
                classes="bg-primary text-white"
                disabled={isNewEspacioLoading}
                onClick={submitHandler}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EspaciosForm;
