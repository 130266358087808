import { get } from "lodash";
import { memo, useEffect, useState } from "react";
import { JsonSchemaGlobalInterface } from "./types.dto";

const SchemaSelect = ({
  schema,
  nameForm,
  modelSetter,
  value,
}: JsonSchemaGlobalInterface) => {
  const widgetProps = get(schema, "widget.props");
  const [enums, setEnums] = useState([]);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setEnums(schema.enum);
  }, [schema.enum]);

  return (
    <div className={`schema-input flex flex-col ${widgetProps.classes}`}>
      <label htmlFor={nameForm}>
        {schema.title} {widgetProps.required && " *"}
      </label>
      <select
        id={nameForm}
        name={nameForm}
        {...widgetProps}
        value={localValue}
        defaultValue={""}
        placeholder="seleccione..."
        className="input-style"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          modelSetter(nameForm, e.target.value);
          setLocalValue(e.target.value);
        }}
      >
        <option disabled value="">
          Seleccione...
        </option>
        {enums.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {schema.description && <small>{schema.description}</small>}
    </div>
  );
};
export default memo(SchemaSelect);
