import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import { TypesButton } from "../../components/types.dto";
import { LoginMethod } from "../../constans/login";
import { ToastContainer } from "react-toastify";
import { useGetToken } from "../../hooks/useAuth";

const Login = () => {
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();
  const { token } = useGetToken();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="max-w-lg mx-auto my-10 bg-gray-100 p-8 rounded-xl shadow shadow-slate-300">
      <ToastContainer />
      <h1 className="text-4xl font-medium">Inicia sesión</h1>
      <p className="text-slate-500">
        Escribe los datos de acceso: correo y contraseña para acceder
      </p>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={async (e) => {
            e.preventDefault();
            const isLogged = await LoginMethod(mail, pass);
            isLogged && navigate("/");
          }}
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Correo *
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={mail}
                placeholder="Ejemplo: micorreo@mail.com"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMail(e.target.value)
                }
                required
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Contraseña *
              </label>
              <div className="text-sm">
                <Link
                  to="/auth/contrasena"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  ¿Olvidó la contraseña?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                value={pass}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPass(e.target.value)
                }
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              />
            </div>
          </div>

          <Button
            type={TypesButton.Submit}
            text="Entrar"
            classes="bg-primary text-white my-4 w-full"
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
