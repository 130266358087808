import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ModalDetailProduct from "../../components/ModalDetailProduct";
import { useLazyGetProductsByRoomQuery } from "../../store/morhanaApi";
import useQuery from "../../hooks/useQuery";
import ProductApiObject from "../../store/dto/producto.dto";
import DataTable from "react-data-table-component";
import Modal from "react-modal";

const EspacioProductos: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [openProd, setOpenProd] = useState<ProductApiObject>();

  const [fetcher, { data, isLoading, isFetching }] =
    useLazyGetProductsByRoomQuery();
  const query = useQuery();
  const prototipo = query.get("prototipo");
  const habitacion = query.get("habitacion");

  const columns: any = [
    {
      cell: (row: ProductApiObject) => (
        <div
          className="bg-cover bg-center w-24 h-24 rounded-xl my-2"
          style={{
            backgroundImage: `url('${row.imagen_url || "/no-image.png"}')`,
          }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: "Código",
      selector: (row: ProductApiObject) => row.codigo,
    },
    {
      name: "Nombre",
      selector: (row: ProductApiObject) => row.nombre_completo,
      sortable: true,
    },
    {
      name: "Tipo producto",
      selector: (row: ProductApiObject) => row.tipo_producto,
      sortable: true,
    },
    {
      name: "P. Unitario",
      selector: (row: ProductApiObject) => row.precio,
      format: (row: ProductApiObject) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(row.precio),
      sortable: true,
    },
    // {
    //   name: "Material",
    //   selector: (row: ProductApiObject) => row.material,
    // },
    // {
    //   name: "Colores",
    //   selector: (row: ProductApiObject) => row.color,
    // },
    {
      cell: (row: ProductApiObject) => (
        <button type="button" onClick={() => handleButtonClick(row)}>
          Ver más
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (habitacion) {
      fetcher({ id: habitacion, endpoint: "desarrollos" }).unwrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habitacion]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleButtonClick = (row: ProductApiObject) => {
    setOpenProd(row);
    setIsOpen(true);
  };

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <ModalDetailProduct openProd={openProd} closeModal={closeModal} />
      </Modal>
      <ToastContainer />
      <div className="flex flez-row">
        <Link to="/espacios" className="mr-2">
          Espacios /
        </Link>
        <Link to={`/espacios/espacio?id=${prototipo}`} className="mr-2">
          Prototipo: {data?.habitacion?.fk_prototipos?.nombre_prototipo}
        </Link>
      </div>
      <div className="flex">
        <h1 className="mb-6 text-xl mr-auto font-bold">
          {`Productos para Área: ${data?.habitacion?.espacio}`}
        </h1>
      </div>
      <div className="mt-8 bg-white rounded-lg p-4 items-center">
        <DataTable
          columns={columns}
          data={data?.productos ?? []}
          progressPending={isLoading || isFetching}
          pagination
        />
      </div>
    </div>
  );
};

export default EspacioProductos;
