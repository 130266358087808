import { get } from "lodash";
import { JsonSchemaGlobalInterface } from "./types.dto";

const SchemaInput = ({
  schema,
  nameForm,
  value,
  modelSetter,
}: JsonSchemaGlobalInterface) => {
  const widgetProps = get(schema, "widget.props");

  return (
    <div
      id={`schema-${nameForm}`}
      className={`schema-input flex flex-col ${widgetProps.classes}`}
    >
      <label className="schema-input__label" htmlFor={nameForm}>
        {schema.title} {widgetProps.required && " *"}
      </label>
      <input
        id={nameForm}
        name={nameForm}
        defaultValue={value}
        {...widgetProps}
        className="input-style"
        placeholder={schema.title}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
          modelSetter(nameForm, e.target.value)
        }
      />
      <small className="schema-input__help">{schema.description}</small>
    </div>
  );
};
export default SchemaInput;
