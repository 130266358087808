import { TypesButton } from "./types.dto";

interface ButtonProps {
  text: String;
  type?: TypesButton;
  classes?: String;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const Button = ({
  onClick,
  onSubmit,
  classes,
  text,
  type = TypesButton.Button,
  disabled,
}: ButtonProps) => {
  return (
    <button
      className={`custom-btn ${classes}`}
      type={type}
      onClick={onClick}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;
