import { useEffect, useState } from "react";
import { useAuth, useGetToken } from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../components/Button";
import { TypesButton } from "../../components/types.dto";
import { useSetVariableMutation } from "../../store/morhanaApi";

const Profile = () => {
  useAuth();
  const { user } = useGetToken();
  const [perVariable, setPerVariable] = useState<string | number>();
  const [setter, { isSuccess, isError }] = useSetVariableMutation();

  useEffect(() => {
    if (user?.variable_price_percentage)
      setPerVariable(user?.variable_price_percentage);
  }, [user?.variable_price_percentage]);

  useEffect(() => {
    if (isError) {
      toast.error(
        "Ocurrió un error al obtener los productos del proyecto al cual intentas acceder, verifica la url.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Cambio de porcentaje realizado con éxito", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess]);

  const setValue = (value: string) => {
    if (!/^0*(?:[1-9][0-9]?|100)$/.test(value)) {
      setPerVariable("");
      toast.error("Debes de ingresar un valor del 1 al 100");
    } else {
      setPerVariable(value);
    }
  };

  return (
    <section className="pt-16 bg-blueGray-50">
      <ToastContainer />
      <div className="w-full lg:w-4/12 px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
          <div className="px-6">
            <div className="flex flex-wrap justify-center">
              <div className="bg-gray-600 rounded-full w-24 h-24 flex items-center justify-center relative -top-12">
                <svg
                  className="w-12 h-12 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="text-center mt-4 mb-8">
              <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                {user?.nombre_completo}
              </h3>
              <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                {user?.rol}
              </div>
              <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                <form
                  className="flex flex-col items-start"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (perVariable) {
                      await setter({ variable: perVariable }).unwrap();
                    }
                  }}
                >
                  <label
                    htmlFor="variable"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Variable procentaje *
                  </label>
                  <div className="mt-2 w-full flex flex-row">
                    <input
                      id="variable"
                      name="variable"
                      type="number"
                      value={perVariable}
                      placeholder="Ejemplo: 10"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(e.target.value)
                      }
                      required
                      className="w-full py-3 border border-slate-200 rounded-l-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                    />
                    <div className="w-12 bg-gray-200 flex items-center justify-center rounded-r-lg">
                      <div className="font-bold">%</div>
                    </div>
                  </div>
                  <small>
                    Escriba el porcentaje (del 1 al 100), el cual afectará el
                    precio final del producto
                  </small>
                  <Button
                    text="Guardar"
                    type={TypesButton.Submit}
                    classes="bg-primary text-white mt-4 mx-auto"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
