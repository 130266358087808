import { memo } from "react";
import SchemaForm from "./SchemaForm";
import Button from "../Button";
import { TypesButton } from "../types.dto";

interface SchemaArrayObjectsProps {
  schema: any;
  customModelValues: any;
  customModelSetter: (key: string, inputValue: String, i: number) => void;
  newElementToArray: () => void;
  deleteElementofArray: (index: number) => void;
  schemaFormClasses?: string;
  buttonMessage?: string;
}

const SchemaArrayObjects = ({
  schema,
  customModelValues,
  customModelSetter,
  newElementToArray,
  deleteElementofArray,
  schemaFormClasses = `flex md:flex-row flex-col items-center`,
  buttonMessage = "Agregar nuevo",
}: SchemaArrayObjectsProps) => {
  return (
    <div className="schema-input flex flex-col">
      {customModelValues.map((_o: any, i: number) => (
        <SchemaForm
          key={`form_hijos_${i}`}
          schema={schema}
          classNames={schemaFormClasses}
          customModelSetter={(key: string, value: String) =>
            customModelSetter(key, value, i)
          }
          customModelValues={_o}
        >
          <button
            onClick={() => deleteElementofArray(i)}
            className="flex items-center"
            type="button"
          >
            <div className="w-6 h-6 text-white bg-red-600 flex items-center justify-center rounded-full mr-2">
              ✖
            </div>
            <span>Eliminar registro</span>
          </button>
        </SchemaForm>
      ))}
      <div className="w-full text-center">
        <Button
          type={TypesButton.Button}
          onClick={newElementToArray}
          text={buttonMessage}
          classes="bg-gray-700 text-white w-64 ml-auto"
        />
      </div>
    </div>
  );
};
export default memo(SchemaArrayObjects);
