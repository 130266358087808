import { useCallback, useReducer } from "react";
import SchemaForm from "../FormsJson/SchemaForm";
import Button from "../Button";
import cotizacion from "../../assets/json/cotizacion.json";
import { TypesButton } from "../types.dto";
import { StateProps } from "../../store/dto/api.dto";

interface AditionalFormProps {
  closeModal: () => void;
  submitModal: (valuesState: StateProps) => void;
}

const initialState: StateProps = {
  complemento: "",
  logistica: "",
  instalacion: "",
  produccion: "",
  honrarios: "",
  fecha_entrega: "",
};

const reducer = (state: StateProps, action: any) => {
  switch (action.type) {
    case "complemento":
      return { ...state, complemento: action.payload };
    case "logistica":
      return { ...state, logistica: action.payload };
    case "instalacion":
      return { ...state, instalacion: action.payload };
    case "produccion":
      return { ...state, produccion: action.payload };
    case "honrarios":
      return { ...state, honrarios: action.payload };
    case "fecha_entrega":
      return { ...state, fecha_entrega: action.payload };
    default:
      throw new Error();
  }
};

const AditionalForm = ({ closeModal, submitModal }: AditionalFormProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dataSetterHandler = useCallback(
    (key: string, inputValue: String) => {
      dispatch({ type: key, payload: inputValue });
    },
    [dispatch]
  );

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        submitModal(state);
        closeModal();
      }}
      className="container mx-auto"
    >
      <div className="flex flex-row items-center mb-4">
        <h1 className="mb-4 text-xl mr-auto font-bold">
          Ingresa la información adicional
        </h1>
      </div>
      <section className="bg-white px-6 py-4 rounded-lg">
        <SchemaForm
          schema={cotizacion}
          customModelValues={state}
          customModelSetter={dataSetterHandler}
          classNames="grid md:grid-cols-4 grid-cols-1 grid-flow-row gap-4"
        />
        <div className="text-center mt-8 mb-4">
          <Button
            text="Aceptar"
            type={TypesButton.Submit}
            classes="bg-primary text-white"
          />
          <Button
            text="Cancelar"
            type={TypesButton.Button}
            classes="ml-2 bg-red-600 text-white"
            onClick={closeModal}
          />
        </div>
      </section>
    </form>
  );
};

export default AditionalForm;
