import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import { MultiSelect } from "react-multi-select-component";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

import { formatToMoney, todayDay } from "../../constans/utils";
import SearchInput from "../../components/SearchInput";

import InputMoney from "../../components/InputMoney";
import ProductCard from "../../components/ProdutCard";

import {
  useLazyGetTypesOfProductsQuery,
  useSetFiltersToProductsMutation,
} from "../../store/morhanaApi";
import TagText from "../../components/TagText";
import ProductApiObject from "../../store/dto/producto.dto";
import ModalDetailProduct from "../../components/ModalDetailProduct";
const RangeSlider = require("react-range-slider-input").default;

const ENUMS_ESTILOS = [
  { label: "Contemporáneo", value: "Contemporáneo" },
  { label: "Playero", value: "Playero" },
  { label: "Minimalista", value: "Minimalista" },
  { label: "Maximalista", value: "Maximalista" },
  { label: "Parisino", value: "Parisino" },
  { label: "Nórdico", value: "Nórdico" },
  { label: "Escandinavo", value: "Escandinavo" },
  { label: "Mexicano", value: "Mexicano" },
  { label: "Rústico", value: "Rústico" },
  { label: "Moderno", value: "Moderno" },
  { label: "Industrial", value: "Industrial" },
  { label: "Ecléctico", value: "Ecléctico" },
  { label: "Art deco", value: "Art deco" },
  { label: "Náutico", value: "Náutico" },
  { label: "Oriental", value: "Oriental" },
  { label: "Boho", value: "Boho" },
  { label: "Romántico", value: "Romántico" },
];

const ProductInsideComp = ({
  product,
  showProduct,
}: {
  product: ProductApiObject;
  showProduct: (row: ProductApiObject) => void;
}) => {
  return (
    <div className="flex flex-col">
      <h5
        className="mb-2 text-xl font-semibold text-center flex items-center justify-center cursor-pointer"
        onClick={() => showProduct(product)}
      >
        <span className="mr-2">{product.nombre_completo}</span>
        <TagText>{product.codigo}</TagText>
      </h5>
      <p className="text-center">{product.estilo}</p>
      <div className="grid grid-cols-2 grid-flow-row gap-2 divide-x">
        <div className="flex flex-col">
          <small className="text-center mb-1">{product.tipo_producto}</small>
          <small className="text-center">
            {formatToMoney(Number(product.precio))}
          </small>
        </div>
        <small className="text-center">
          {product.acabado && `Acabado: ${product.acabado}`}
          {product.material &&
            !product.acabado &&
            `Material: ${product.material}`}
        </small>
      </div>
    </div>
  );
};

const Busqueda: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [estilos, setEstilos] = useState([]);
  const [minMoney, setMinMoney] = useState(0);
  const [maxMoney, setMaxMoney] = useState(15000);
  const [arrMoney, setArrMoney] = useState<number[]>([]);

  const [openProd, setOpenProd] = useState<ProductApiObject>();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [searchTypesTrigger, { data: dataTypes }] =
    useLazyGetTypesOfProductsQuery();
  const [
    filterTrigger,
    {
      data: dataFiltered,
      isLoading: isLoadingFilters,
      isError: isErrorFilters,
    },
  ] = useSetFiltersToProductsMutation();

  useEffect(() => {
    searchTypesTrigger("").unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const [first, second] = arrMoney;
    if (first) setMinMoney(first);
    if (second) setMaxMoney(second);
  }, [arrMoney]);

  // /*** SETEAMOS STATE ***/
  // useEffect(() => {
  //   if (isArray(data)) dispatch(setOverallData(data));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  // /*** FILTRO DE CATEGORÍAS ***/
  // useEffect(() => {
  //   dispatch(filterDataByCategories(categorias));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categorias]);

  // /*** FILTRO DE ESTILOS ***/
  // useEffect(() => {
  //   dispatch(filterDataByStyle(estilos));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [estilos]);

  useEffect(() => {
    const deb = debounce(
      function () {
        const categoriasMapped = categorias.map((cat: any) => cat.value);
        const estilosMapped = estilos.map((est: any) => est.value);
        filterTrigger({
          categorias: categoriasMapped,
          estilos: estilosMapped,
          minMoney,
          maxMoney,
        }).unwrap();
      },
      500,
      { maxWait: 1000 }
    );
    deb();
  }, [minMoney, maxMoney, categorias, estilos, filterTrigger]);

  useEffect(() => {
    if (isErrorFilters === true) {
      toast.error(
        "¡No se encontró el producto que buscabas, prueba con otro.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [isErrorFilters]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleButtonClick = (row: ProductApiObject) => {
    setOpenProd(row);
    setIsOpen(true);
  };

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <ModalDetailProduct openProd={openProd} closeModal={closeModal} />
      </Modal>
      <ToastContainer />
      <div className="flex">
        <h1 className="text-xl mr-auto font-bold">Nueva Búsqueda</h1>
        <div className="flex items-center">
          <span className="mr-3">Fecha de registro</span>
          <div className="custom-btn bg-white text-gray-900">{todayDay}</div>
        </div>
      </div>
      <div className="mt-8 flex md:flex-row flex-col items-start relative">
        {isLoadingFilters && (
          <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-gray-200">
            <div className="flex items-center justify-center mt-8">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="md:mr-2 bg-white rounded-lg p-4 w-72">
          <div className="flex flex-col">
            <label>Categoría</label>
            <MultiSelect
              options={
                dataTypes && Array.isArray(dataTypes)
                  ? dataTypes.map(({ tipo_producto }) => {
                      return { label: tipo_producto, value: tipo_producto };
                    })
                  : []
              }
              value={categorias}
              onChange={setCategorias}
              labelledBy="Categoría"
              className="border rounded-full border-gray-100 px-4 bg-white min-h-12  focus:outline-none pt-1"
            />
          </div>
          <div className="flex flex-col my-4">
            <label>Estilo</label>
            <MultiSelect
              options={ENUMS_ESTILOS}
              value={estilos}
              onChange={setEstilos}
              labelledBy="Estílo"
              className="border rounded-full border-gray-100 px-4 bg-white min-h-12  focus:outline-none pt-1"
            />
          </div>
          <div className="flex flex-col">
            <label>Precio MXN$</label>
            <div className="grid grid-cols-2 grid-flow-row gap-2">
              <InputMoney
                value={String(minMoney)}
                placehoder="Min"
                getValueFromInput={(value: string) =>
                  setMinMoney(Number(value))
                }
              />
              <InputMoney
                value={String(maxMoney)}
                placehoder="Max"
                getValueFromInput={(value: string) =>
                  setMaxMoney(Number(value))
                }
              />
            </div>
          </div>
          <div className="my-4">
            <RangeSlider
              min={minMoney}
              max={20000}
              defaultValue={[0, maxMoney]}
              onInput={(values: Array<number>) => setArrMoney([...values])}
            />
          </div>
        </div>
        <div className="flex-1 md:ml-2">
          <div className="bg-white rounded-lg p-4">
            <div className="flex flex-col">
              <Link
                to="/productos/nuevo"
                className="ml-auto custom-btn bg-primary text-white"
              >
                Cargar nuevo(s) producto(s)
              </Link>
              <SearchInput
                getSearchText={setSearchTerm}
                buttonClicked={async () =>
                  filterTrigger({
                    nombre: searchTerm,
                  }).unwrap()
                }
              />
            </div>
          </div>
          {isLoadingFilters ? (
            <div className="flex items-center justify-center mt-8">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          ) : isErrorFilters ? (
            <div className="flex p-12 w-full items-center justify-center">
              <div
                className="flex bg-red-100 rounded-lg p-4 mb-4 text-sm text-red-700"
                role="alert"
              >
                <svg
                  className="w-5 h-5 inline mr-3"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <div>
                  <span className="font-medium">Oops!</span> Al parecer no
                  pudimos conectarnos a la BD, intenta nuevamente
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-8 grid md:grid-cols-4 grid-cols-2 grid-flow-row gap-4">
              {(dataFiltered ?? []).map(
                (product: ProductApiObject, i: number) => (
                  <ProductCard
                    imgSource={product.imagen_url || "no-image.png"}
                    key={`product_${i}`}
                  >
                    <ProductInsideComp
                      product={product}
                      showProduct={handleButtonClick}
                    />
                  </ProductCard>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Busqueda;
