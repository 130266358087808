import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import { TypesButton } from "../../components/types.dto";
import { RecoveryMethod } from "../../constans/login";

const Forgot = () => {
  const [mail, setMail] = useState("");

  return (
    <div className="max-w-lg mx-auto my-10 bg-gray-100 p-8 rounded-xl shadow shadow-slate-300">
      <ToastContainer />
      <h1 className="text-4xl font-medium">Cambiar contraseña</h1>
      <p className="text-slate-500">
        ¿Olvidaste tu contraseña? Ingresa el correo de registro para enviar las
        instrucciones siguientes...
      </p>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await RecoveryMethod(mail);
        }}
        className="my-10"
      >
        <div className="flex flex-col space-y-5">
          <label htmlFor="email">
            <p className="font-medium text-slate-700 pb-2">Correo</p>
            <input
              id="email"
              name="email"
              type="email"
              value={mail}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Ejemplo: micorreo@mail.com"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMail(e.target.value)
              }
            />
          </label>

          <Button
            type={TypesButton.Submit}
            text="Reestablecer contraseña"
            classes="bg-primary text-white my-4 w-full"
          />
          <Link
            to="/auth/login"
            className="text-indigo-600 font-medium inline-flex space-x-1 items-center"
          >
            Regresar a Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Forgot;
