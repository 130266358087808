import { Link, Outlet } from "react-router-dom";

import { ROUTES, RouteObject } from "../constans/routes";
import logo from "../assets/logo.svg";
import { useAuth, useGetToken } from "../hooks/useAuth";

const DefaultLayout: React.FC = () => {
  useAuth();
  const { user } = useGetToken();

  const FinalRoutes = ROUTES.filter((objRoute) => {
    if (user?.rol !== "admin") {
      return objRoute.to !== "/catalogos";
    }
    return objRoute;
  });

  return (
    <div className="flex">
      <aside className="w-auto bg-white w-44 flex flex-col bg-shadow-xl p-2">
        <Link className="menu-btn my-0" to="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
        {FinalRoutes.map(({ to, label, icon }: RouteObject, i: number) => (
          <Link key={`route-${i}-${label}`} className="menu-btn" to={to}>
            <img src={icon} alt={`icon-${icon}`} className="w-7" />
            <p className="text-sm">{label}</p>
          </Link>
        ))}
      </aside>
      <div className="w-full flex flex-col bg-bg-app min-h-screen">
        <header className="w-full bg-white min-h-16 bg-shadow-lg py-2 pl-2 pr-4 flex">
          <div className="flex ml-auto">
            <div className="flex flex-col mr-4">
              <Link to="/profile" className="font-bold text-xl">
                {user?.nombre_completo}
              </Link>
              <p className="text-xs font-color-300 uppercase">{user?.rol}</p>
            </div>
            <div className="bg-gray-300 rounded-xl w-14 h-14 flex items-center justify-center">
              <svg
                className="w-12 h-12 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </header>
        <main className="p-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DefaultLayout;
