import {
  busqueda,
  nuevo_proy,
  espacios,
  levantamiento,
  clientes,
  dbicon,
} from "../assets/icons/index";

export interface RouteObject {
  to: string;
  label: string;
  icon: string;
}

export const ROUTES: Array<RouteObject> = [
  { to: "/proyecto", label: "Nuevo proyecto", icon: nuevo_proy },
  { to: "/", label: "Buscador", icon: busqueda },
  { to: "/espacios", label: "Solucionador de espacios", icon: espacios },
  { to: "/levantamientos", label: "Levantamientos", icon: levantamiento },
  { to: "/clientes", label: "Clientes", icon: clientes },
  { to: "/catalogos", label: "Catálogos", icon: dbicon },
];
