import { configureStore } from "@reduxjs/toolkit";
import { morhanaApi } from "./morhanaApi";
import projectReducer from "./projectSlice";
import espacioReducer from "./espacioSlice";
import productoSlice from "./productoSlice";
import habitacionesSlice from "./habitacionesSlice";
import productosHabitacionSlice from "./productosHabitacionSlice";
// import filtersSlice from "./filtersSlice";

const store = configureStore({
  reducer: {
    [morhanaApi.reducerPath]: morhanaApi.reducer,
    project: projectReducer,
    espacio: espacioReducer,
    product: productoSlice,
    habitacion: habitacionesSlice,
    productosHabitacion: productosHabitacionSlice,
    // filtered: filtersSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(morhanaApi.middleware),
});

export default store;
