import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import ProductCard from "../../components/ProdutCard";
import Button from "../../components/Button";
import SchemaForm from "../../components/FormsJson/SchemaForm";
import { TypesButton } from "../../components/types.dto";
import habitaciones from "../../assets/json/habitaciones.json";

import { cintaMetrica } from "../../assets/icons/index";
import {
  useLazyGetPrototiposQuery,
  useSetFileToTypeOfDevMutation,
  useUpdateModelOfDevelopmentsMutation,
} from "../../store/morhanaApi";

import { HabitacionSliceProps } from "../../store/dto/habitacion.dto";
import {
  addDataToProductModel,
  getHabitacionModel,
} from "../../store/habitacionesSlice";
import useQuery from "../../hooks/useQuery";
import { imageValidator } from "../../constans/utils";
import { isEmpty } from "lodash";
import InputImage from "../../components/InputImage";

const EspacioDetalle: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [habId, setHabId] = useState(0);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState("");

  const query = useQuery();
  const id = query.get("id");

  const [fetcher, { data, isLoading, isFetching }] =
    useLazyGetPrototiposQuery();
  const [setter, { isError, isSuccess }] =
    useUpdateModelOfDevelopmentsMutation();
  const [fileSetter, { isError: isErrorFile, isSuccess: isSuccessFile }] =
    useSetFileToTypeOfDevMutation();
  const localModel: HabitacionSliceProps = useSelector(getHabitacionModel);
  const dispatch = useDispatch();

  const dataSetterHandler = (key: string, inputValue: String) => {
    const KEYS = ["ancho", "alto", "largo", "metros_cuadrados"];
    dispatch(
      addDataToProductModel({
        key,
        data: KEYS.includes(key) ? Number(inputValue) : inputValue,
      })
    );
  };

  const openModal = (hab: any) => {
    setHabId(hab.id);
    setImageUrl(hab.plano_url);
    const obj: any = {
      espacio: hab.espacio,
      unidad_medida: hab.unidad_medida,
      ancho: Number(hab.ancho),
      alto: Number(hab.alto),
      largo: Number(hab.largo),
      metros_cuadrados: Number(hab.metros_cuadrados),
    };
    Object.keys(obj).forEach((key) => {
      dataSetterHandler(key, obj[key]);
    });
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    const obj: any = {
      espacio: "",
      unidad_medida: "",
      ancho: "",
      alto: "",
      largo: "",
      metros_cuadrados: "",
    };
    Object.keys(obj).forEach((key) => {
      dataSetterHandler(key, obj[key]);
    });
  };

  useEffect(() => {
    id && fetcher(id).unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (image && habId) {
      fileSetter({ id: habId, type: "habitaciones", file: image }).unwrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, habId]);

  useEffect(() => {
    if (isError || isErrorFile) {
      toast.error("Ocurrió un error al querer editar los datos.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError, isErrorFile]);

  useEffect(() => {
    if (isSuccess || isSuccessFile) {
      toast.success("Se editó el nombre del desarrollo con éxito", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess, isSuccessFile]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="Overlay"
      >
        <div className="flex flex-row items-center">
          <h2 className="mr-auto text-2xl">
            Detalle de Habitación / Prototipo
          </h2>
          <button onClick={closeModal}>
            <strong className="text-xl align-center cursor-pointer alert-del">
              &times;
            </strong>
          </button>
        </div>
        <div className="flex md:flex-row flex-col mt-8">
          <div className="flex-1 md:mr-4 mr-0 md:my-0 my-4">
            <InputImage imageUrl={imageUrl} modelSetter={setImage} />
          </div>
          <form
            onSubmit={async function (e) {
              e.preventDefault();
              id &&
                Object.keys(localModel).length > 0 &&
                (await setter({
                  type: "habitaciones",
                  id: Number(habId),
                  value: localModel,
                }).unwrap());
            }}
            className="flex flex-col justify-center container mx-auto flex-1"
          >
            <SchemaForm
              classNames="grid xl:grid-cols-2 md:grid-cols-1 grid-cols-1 grid-flow-row gap-4"
              schema={habitaciones}
              // @ts-ignore
              customModelSetter={dataSetterHandler}
              customModelValues={localModel}
            />
            <Button
              text="Aceptar"
              type={TypesButton.Submit}
              classes="bg-primary text-white ml-auto mt-4"
            />
          </form>
        </div>
      </Modal>
      <ToastContainer />
      <div className="lg:p-8 md:p-4 p-2">
        <div className="flex flex-col">
          <div className="flex flez-row">
            <Link to="/espacios">Regresar</Link>
          </div>
          <h1 className="mb-6 text-xl mr-auto font-bold">
            {data?.nombre_prototipo}
          </h1>
        </div>
        <div className="mt-8 bg-white rounded-lg p-4 items-center">
          <h2 className="text-xl font-semibold">Espacios del prototipo</h2>
          {isLoading || isFetching ? (
            <div className="flex items-center justify-center my-8">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          ) : (
            <div className="mt-8 grid md:grid-cols-4 grid-cols-2 grid-flow-row gap-4">
              {(data?.habitaciones ?? []).map((hab: any, i: number) => (
                <ProductCard
                  imgSource={imageValidator(hab.plano_url)}
                  key={`model_${i}`}
                >
                  <div className="flex flex-col items-center">
                    <h5 className="mb-2 text-xl font-semibold text-center">
                      {hab.espacio}
                    </h5>
                    <div className="flex flex-row justify-center">
                      <img src={cintaMetrica} alt="cinta metrica" />
                      <span className="ml-2">{hab.metros_cuadrados} m²</span>
                    </div>
                    <div
                      className={`mb-4 grid grid-cols-${
                        !isEmpty(hab.largo) && hab.largo !== 0 ? "3" : "2"
                      } divide-x place-content-center grid-flow-row gap-4 my-2`}
                    >
                      {!isEmpty(hab.largo) && hab.largo !== 0 && (
                        <div className="flex flex-col justify-center items-center">
                          <span className="text-xs">Largo:</span>
                          <p className="ml-2">{hab.largo} m²</p>
                        </div>
                      )}
                      <div className="flex flex-col justify-center items-center">
                        <span className="text-xs">Ancho:</span>
                        <p className="ml-2">{hab.ancho} m²</p>
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <span className="text-xs">Alto:</span>
                        <p className="ml-2">{hab.alto} m²</p>
                      </div>
                    </div>
                    <div className="flex flex-row w-full mt-4 relative">
                      <button
                        type="button"
                        className="flex-1 py-4 w-full bg-primary text-white"
                        onClick={() => openModal(hab)}
                      >
                        Ver modelo
                      </button>
                      <Link
                        to={`/espacios/productos?prototipo=${id}&habitacion=${hab.id}`}
                        className="flex-1 py-4 w-full bg-secondary text-gray-800 text-center"
                      >
                        Productos
                      </Link>
                    </div>
                  </div>
                </ProductCard>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EspacioDetalle;
