import { toast } from "react-toastify";
import Api from "./api";

export const LoginMethod = async (email: string, password: string) => {
  try {
    const api = new Api();
    const data = await api.http.post("auth/login", { email, password });
    if (data?.user) {
      localStorage.setItem("session", JSON.stringify(data));
      return true;
    }
    toast.warning(
      "No se pudo iniciar sesión correctamente, verifica las credenciales de acceso.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return false;
  } catch (error) {
    toast.error(
      "No se pudo iniciar sesión correctamente, verifica las credenciales de acceso.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return false;
  }
};

export const RecoveryMethod = async (email: string) => {
  try {
    const api = new Api();
    const data = await api.http.post("auth/recovery", {
      email,
    });
    if (data?.type && data?.type === "success") {
      toast.success(
        "Enviado con éxito, espera las instrucciones en tu correo",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return true;
    }
    toast.warning(
      "No se pudo generar la nueva contraseña, intenta enviando los datos nuevamente.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return false;
  } catch (error) {
    toast.error(
      "No se pudo generar la nueva contraseña, intenta enviando los datos nuevamente.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return false;
  }
};

export const newPasswordSetter = async (password: string, token: string) => {
  try {
    const api = new Api();
    const data = await api.http.post(`auth/newpassword`, {
      password,
      token,
    });
    if (data) {
      toast.success(
        "Tu contraseña se cambió con éxito. Ya puedes iniciar sesión",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return true;
    }
  } catch (error) {
    toast.error(
      "No se pudo generar la nueva contraseña, intenta enviando los datos nuevamente.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return false;
  }
};
