import { memo, useRef, useState } from "react";
import { JsonSchemaGlobalInterface } from "./types.dto";
import Button from "../Button";

import { get } from "lodash";

const SchemaInputFile = ({
  schema,
  nameForm,
  value,
  modelSetter,
}: JsonSchemaGlobalInterface) => {
  const inputRef = useRef<any>(null);
  const [file, setfile] = useState(value || null);
  const widgetProps = get(schema, "widget.props", {});

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const value = widgetProps.multiple ? files : get(files, "0", files);
    modelSetter(nameForm, value);
    setfile(value);
  };

  return (
    <div>
      <div className="w-full bg-bg-app">
        <label className="flex justify-center items-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
          <div className="flex flex-col items-center space-x-2">
            <span className="font-bold">
              {schema.title || "Adjuntar archivo"}{" "}
              {widgetProps.required && " *"}
            </span>
            <span className="font-light">Arrastra o suelta el archivo</span>
            <Button
              text={file?.name || "Seleccionar archivos"}
              classes="bg-primary text-white"
              onClick={() => inputRef?.current?.click()}
            />
          </div>
          <input
            ref={inputRef}
            type="file"
            name="file_upload"
            className="hidden"
            {...widgetProps}
            onChange={fileHandler}
          />
        </label>
      </div>
      {schema.description && (
        <span className="text-small">{schema.description}</span>
      )}
    </div>
  );
};

export default memo(SchemaInputFile);
