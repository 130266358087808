import SchemaForm from "./FormsJson/SchemaForm";
import { MedidasEspaciosState } from "../store/dto/project.dto";
import { ModelSetterForRoom } from "./FormsJson/types.dto";

interface FormRoomCardSpaceProps {
  keyIndex: number;
  familiaName?: string;
  schemaObject: any;
  customModelValues: MedidasEspaciosState;
  customModelSetter: ({ index, key, value }: ModelSetterForRoom) => void;
  deleteModel?: (index: number) => void;
}

const FormRoomCardSpace = ({
  keyIndex,
  familiaName,
  schemaObject,
  customModelValues,
  customModelSetter,
  deleteModel,
}: FormRoomCardSpaceProps) => {
  const dataHandler = (key: string, inputValue: String) => {
    customModelSetter({
      index: keyIndex,
      key,
      value: inputValue,
    });
  };

  const deleteHandler = () => {
    if (
      deleteModel &&
      window.confirm("¿Estas segur@ de eliminar la habitación seleccionada?")
    ) {
      deleteModel(keyIndex);
    }
  };

  return (
    <div className={`mt-6 border rounded-lg flex flex-col relative`}>
      <div
        className="w-full h-32 bg-center bg-cover bg-no-repeat rounded-t"
        style={{ backgroundImage: `url('${customModelValues.imagen_url}')` }}
      />
      <div className={`flex flex-col p-2`}>
        {familiaName && (
          <p className="text-center text-xl font-bold">{familiaName}</p>
        )}
        <div className={`flex mt-2 w-full items-center `}>
          <SchemaForm
            schema={schemaObject}
            customModelValues={customModelValues}
            customModelSetter={dataHandler}
            classNames="grid md:grid-cols-2 grid-cols-1 grid-flow-row gap-2 w-full"
          />
        </div>
      </div>
      {deleteModel && (
        <button
          type="button"
          className="mx-2 flex items-center justify-center p-4 w-6 h-6 rounded-full bg-red-600 text-white absolute -right-4 -top-2"
          onClick={deleteHandler}
        >
          -
        </button>
      )}
    </div>
  );
};

export default FormRoomCardSpace;
