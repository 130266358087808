import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import { ActionSlice } from "./dto/store.dto";
import ProductApiObject from "./dto/producto.dto";
import { find } from "lodash";

const initialState: {
  [key: string]: { producto: ProductApiObject; cantidad: number | string }[];
} = {};

export const productosHabitacionSlice = createSlice({
  name: "productosHabitacion",
  initialState,
  reducers: {
    resetState: (state: any) => {
      state = {};
    },
    setHabitacion: (state: any, action: ActionSlice) => {
      state[action.payload.key] = [];
    },
    setProductosToHabitacion: (state: any, action: ActionSlice) => {
      const arrExisted = state[action.payload.key as any];
      if (
        !find(
          arrExisted,
          (o: { producto: ProductApiObject; cantidad: number | string }) =>
            o.producto.uuid === action.payload.data.producto.uuid
        )
      )
        state[action.payload.key as any].push(action.payload.data);
      else {
        const filtered = arrExisted.filter(
          (o: { producto: ProductApiObject; cantidad: number | string }) =>
            o.producto.uuid !== action.payload.data.producto.uuid
        );
        filtered.push(action.payload.data);
        state[action.payload.key as any] = filtered;
      }
    },
    deleteProductoToHabitacion: (state: any, action: ActionSlice) => {
      const arrExisted = state[action.payload.key as any];
      const filtered = arrExisted.filter(
        (o: { producto: ProductApiObject; cantidad: number | string }) =>
          o.producto.uuid !== action.payload.data
      );
      state[action.payload.key as any] = filtered;
    },
  },
});

export const getOverallHabitacionesProductos = (state: any) =>
  state.productosHabitacion;

export const processListenerMiddleware = createListenerMiddleware();

export const {
  setHabitacion,
  setProductosToHabitacion,
  deleteProductoToHabitacion,
} = productosHabitacionSlice.actions;

export default productosHabitacionSlice.reducer;
