interface ProductCardProps {
  children: React.ReactNode;
  imgSource: string;
}

const ProductCard = ({ children, imgSource }: ProductCardProps) => {
  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-lg transition ease-in-out">
      <img
        className="rounded-t-lg mx-auto h-32 mt-2"
        src={imgSource}
        alt="custom_image"
      />
      <div className="p-5">{children}</div>
    </div>
  );
};

export default ProductCard;
