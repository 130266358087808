import { useState, useEffect, useMemo, memo } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Modal from "react-modal";

import {
  useLazyGetAiImagesToProyectoQuery,
  useLazyGetProyectDetailsQuery,
  useSetProductsToProyectMutation,
} from "../../store/morhanaApi";
import ordenCompra from "../../assets/orden-compra.png";
import Api from "../../constans/api";

import { TypesButton } from "../../components/types.dto";
import ProductApiObject from "../../store/dto/producto.dto";
import {
  HabitacionApiResponse,
  HabitacionMedidaResponse,
} from "../../store/dto/habitacion.dto";

import useQuery from "../../hooks/useQuery";

import { useGetToken } from "../../hooks/useAuth";
import Button from "../../components/Button";
import StickyBar from "../../components/StickyBar";
import ModalDetailProduct from "../../components/ModalDetailProduct";
import HabitacionTab from "../../components/Proyecto/HabitacionTab";
import { useDispatch, useSelector } from "react-redux";
import {
  getOverallHabitacionesProductos,
  setHabitacion,
} from "../../store/productosHabitacionSlice";
import { groupBy, isPlainObject } from "lodash";
import { customOrder, getDiffDays } from "../../constans/utils";
import { addDataToModel } from "../../store/projectSlice";
import AditionalForm from "../../components/Proyecto/AditionalForm";
import { StateProps } from "../../store/dto/api.dto";

const ProyectosDetail: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [typeRooms, setTypeRooms] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [arrayOfRooms, setArrayOfRooms] = useState<
    HabitacionApiResponse[] | HabitacionMedidaResponse[]
  >([]);
  const [openProd, setOpenProd] = useState<ProductApiObject>();
  const [preselection, setPreselection] = useState<any>({});
  const [finalform, setfinalForm] = useState<StateProps | {}>({});

  const [arrOfImages, setArrOfImages] = useState<string[] | boolean>(false);
  const [imagesHasError, setImagesHasError] = useState<string[] | boolean>(
    false
  );
  const api = useMemo(() => new Api(), []);

  const query = useQuery();
  const uuid = query.get("uuid");
  const { token } = useGetToken();
  const dispatch = useDispatch();

  const overallModel = useSelector(getOverallHabitacionesProductos);

  const [projectFetcher, { data, isLoading, isError, isFetching, isSuccess }] =
    useLazyGetProyectDetailsQuery();

  const [
    productsToProjectFetcher,
    { isLoading: PTPIsLoading, isError: PTPIsError, isSuccess: PTPIsSuccess },
  ] = useSetProductsToProyectMutation();
  const [getAiImagesFetcher, { isSuccess: AIImagesSucess }] =
    useLazyGetAiImagesToProyectoQuery();

  const totalTodosProductos = Object.keys(overallModel).reduce((acc, key) => {
    const totalPorHabitacion = overallModel[key].reduce(
      (
        accu: number,
        {
          producto: { precio },
          cantidad,
        }: { producto: ProductApiObject; cantidad: number }
      ) => {
        return accu + precio * cantidad;
      },
      0
    );
    return acc + totalPorHabitacion;
  }, 0);

  const interfaceHasProducts = Object.keys(overallModel).filter(
    (key) => overallModel[key].length > 0
  );

  const diasRestantes = getDiffDays(data?.extras?.fecha_entrega, new Date());

  const canDownloadFiles =
    (isPlainObject(preselection) && Object.keys(preselection).length > 0) ||
    PTPIsSuccess;

  useEffect(() => {
    if (uuid) {
      projectFetcher(uuid);
      dispatch(
        addDataToModel({
          key: "uuid",
          data: uuid,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (
      data?.propiedad?.habitaciones &&
      Array.isArray(data?.propiedad?.habitaciones) &&
      data?.propiedad?.habitaciones.length > 0
    ) {
      const habitaciones = data?.propiedad?.habitaciones?.map(
        (habitacion) => habitacion
      );
      setArrayOfRooms(customOrder(habitaciones));
      habitaciones.forEach(({ id }) =>
        dispatch(setHabitacion({ key: String(id), data: [] }))
      );
      setTypeRooms("propiedad");
    } else if (
      data?.HabitacionesMedidas &&
      Array.isArray(data?.HabitacionesMedidas) &&
      data?.HabitacionesMedidas.length > 0
    ) {
      const habitaciones = data?.HabitacionesMedidas?.map(
        (habitacion) => habitacion
      );
      setArrayOfRooms(customOrder(habitaciones));
      data?.HabitacionesMedidas.forEach(({ id }) =>
        dispatch(setHabitacion({ key: String(id), data: [] }))
      );
      setTypeRooms("manual");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.propiedad?.habitaciones, data?.HabitacionesMedidas]);

  useEffect(() => {
    if (AIImagesSucess && uuid) {
      projectFetcher(uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AIImagesSucess, uuid]);

  useEffect(() => {
    if (Object.keys(finalform).length > 0) {
      setModalFormOpen(true);
      submitProductsRelation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalform]);

  useEffect(() => {
    if (data?.imagenes_ai) {
      setImagesHasError(false);
      const notProcessed = data?.imagenes_ai.find((o: any) => o?.result?.id);
      const hasError = data?.imagenes_ai.find((o: any) => o?.result?.error_log);
      if (notProcessed) setArrOfImages(false);
      else if (hasError) setImagesHasError(true);
      else
        setArrOfImages(
          data?.imagenes_ai.reduce((arr: string[], o: any) => {
            if (o?.result) {
              if (Array.isArray(o?.result)) arr.push(o?.result[0]);
              else arr.push(o?.result);
            }
            return arr;
          }, [])
        );
    }
  }, [data?.imagenes_ai]);

  useEffect(() => {
    if (data?.ProyectoProductos && Array.isArray(data?.ProyectoProductos)) {
      const groupByHabitacion = groupBy(
        data?.ProyectoProductos,
        typeRooms === "manual" ? "habitacion_proyecto_id" : "habitacion_id"
      );
      setPreselection({ ...groupByHabitacion });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.ProyectoProductos,
    PTPIsSuccess,
    typeRooms,
    isSuccess,
    canDownloadFiles,
  ]);

  useEffect(() => {
    if (isError) {
      toast.error(
        "Ocurrió un error al obtener los productos del proyecto al cual intentas acceder, verifica la url.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [isError]);

  useEffect(() => {
    if (PTPIsError) {
      toast.error(
        "Ocurrió un error al enviar la inforamción y relacionar los productos, intenta nuevamente.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [PTPIsError]);

  const submitProductsRelation = () => {
    const cloned = Object.keys(overallModel).reduce((obj: any, key) => {
      const keyObject = String(key);
      if (keyObject && !obj[keyObject as string]) {
        obj[keyObject as string] = overallModel[key].map(
          ({
            producto: { uuid },
            cantidad,
          }: {
            producto: ProductApiObject;
            cantidad: number;
          }) => {
            return { uuid, cantidad };
          }
        );
      }
      return obj;
    }, {});

    if (!Array.isArray(arrOfImages)) {
      toast.warning(
        "Para continuar, además de la selección de productos es necesario consultar las imagenes de IA que aún se están procesando..."
      );
    } else if (uuid && interfaceHasProducts.length > 0) {
      productsToProjectFetcher({
        uuid,
        productos: cloned,
        relation: typeRooms,
        datosFinales: finalform,
      }).unwrap();
      projectFetcher(uuid);
      setfinalForm({});
      closeModalForm();
    } else {
      toast.warning(
        "Agrega uno o más productos a la orden de compra para guardarlo al proyecto"
      );
    }
  };

  const cotizacionDownloader = async () => {
    setLoading(true);
    toast.info(
      "Generando cotización... por favor espere un momento, se descargará automáticamente cuando esté listo.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    const file = await api.http.getStream(
      `proyecto/generate/cotizacion/${uuid}`,
      token
    );
    const blob = new Blob([file], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `cotizacion_${uuid}.pdf`);
    link.click();
    setLoading(false);
  };

  const ordenDeCompraDownloader = async () => {
    setLoading(true);
    toast.info(
      "Generando orden de compra... por favor espere un momento, se descargará automáticamente cuando esté listo.",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    const file = await api.http.getStream(
      `proyecto/generate/ordencompra/${uuid}`,
      token
    );
    const blob = new Blob([file], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `ordencompra_${uuid}.pdf`);
    link.click();
    setLoading(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeModalForm = () => {
    setModalFormOpen(false);
  };

  const handleButtonClick = (row: ProductApiObject) => {
    setOpenProd(row);
    setIsOpen(true);
  };

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <ModalDetailProduct openProd={openProd} closeModal={closeModal} />
      </Modal>
      <Modal isOpen={modalFormOpen} onRequestClose={closeModalForm}>
        <AditionalForm submitModal={setfinalForm} closeModal={closeModalForm} />
      </Modal>
      <ToastContainer />
      {Object.keys(preselection).length === 0 && (
        <StickyBar
          total={totalTodosProductos}
          budget={Number(data?.budget) || 0}
        />
      )}
      <div className="flex flex-row items-center mb-4">
        <h1 className="mb-6 text-2xl mr-auto font-bold">Proyecto: {uuid}</h1>
        <Link to="/proyecto" className="custom-btn bg-primary text-white">
          Realizar nuevo proyecto
        </Link>
      </div>
      <div className="flex flex-col">
        <section className="flex md:flex-row flex-col-reverse">
          {data?.budget === 0 && (
            <article className="p-4 rounded-xl bg-gray-200 shadow m-4 flex-1">
              <div className="flex items-center justify-center h-full px-4">
                {!Array.isArray(arrOfImages) && imagesHasError === false && (
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div className="ml-4">
                        Las imágenes se siguen procesando, use el botón de abajo
                        para verificar si ya se procesaron correctamente...
                      </div>
                    </div>
                    <Button
                      text="Consultar imagenes"
                      classes="bg-white text-gray-900 mt-4"
                      onClick={() => uuid && getAiImagesFetcher(uuid)}
                    />
                  </div>
                )}
                {imagesHasError === true && (
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                      <div className="ml-4 text-red-700">
                        Ocurrió un error con la API, al generar el documento se
                        hará con imágenes <strong>no exactas</strong> de acuerdo
                        a lo indicado en el proyecto.
                      </div>
                    </div>
                  </div>
                )}
                {Array.isArray(arrOfImages) && (
                  <Carousel showThumbs={false}>
                    {arrOfImages.map((urlImage: string, i: number) => (
                      <img
                        key={`img_${i}_${urlImage}`}
                        alt={`ai_${i}_image`}
                        src={urlImage}
                      />
                    ))}
                  </Carousel>
                )}
              </div>
            </article>
          )}
          <article
            className={
              data?.budget === 0
                ? "flex flex-col m-4 flex-1"
                : "flex md:flex-row flex-col m-4 flex-1"
            }
          >
            <div
              className={`p-4 rounded-xl shadow ${
                data?.budget === 0 ? "mb-4" : "m-2"
              } flex-1 gradient-green flex items-center justify-center`}
            >
              <div className="flex flex-col">
                <p className="text-xl mb-4">Descargar Orden de Compra</p>
                <div className="flex flex-row items-center">
                  <img src={ordenCompra} alt="Excel icon" className="w-24" />
                  {canDownloadFiles && (
                    <Button
                      text="Descargar"
                      classes="bg-white text-gray-900 ml-12"
                      type={TypesButton.Button}
                      onClick={ordenDeCompraDownloader}
                      disabled={loading}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`p-4 rounded-xl shadow ${
                data?.budget === 0 ? "mt-4" : "m-2"
              } flex-1 gradient-pink flex items-center justify-center`}
            >
              <div className="flex flex-row items-center justify-center">
                <p className="text-white text-xl mr-4">Tiempo de entrega</p>
                <div className="flex items-center justify-center rounded-full border-8 border-pink-dark h-24 w-24">
                  <div className="flex flex-col items-center justify-center rounded-full h-16 w-16 bg-pink-clear">
                    <p className="font-semibold text-pink-dark text-sm">
                      {diasRestantes >= 0 ? diasRestantes : 0}
                    </p>
                    <small className="text-pink-dark">Días</small>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
        <div className="m-4 p-4 bg-white shadow-lg flex-1 rounded-xl">
          {isLoading || isFetching ? (
            <div className="flex items-center justify-center">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] mr-4"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
              <span className="text-uppercase">
                Cargando información del proyecto, por favor espere...
              </span>
            </div>
          ) : (
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList className="flex md:flex-row flex-col md:items-center justify-around mt-2 mb-4">
                {arrayOfRooms.map((habitacion, i) => (
                  <Tab
                    key={`Tab-${habitacion.espacio}-${i}`}
                    className={`cursor-pointer uppercase md:my-0 my-2 ${
                      i === tabIndex ? "underline underline-offset-8" : ""
                    }`}
                  >
                    <div className="flex flex-row items-center">
                      <div className="mr-2">{habitacion.espacio}</div>
                      {!Array.isArray(preselection[habitacion.id]) && (
                        <div className="py-1 px-2 rounded bg-gray-700 text-white">
                          {(overallModel[habitacion.id] ?? []).length}
                        </div>
                      )}
                    </div>
                  </Tab>
                ))}
              </TabList>
              {arrayOfRooms.map((habitacion, i) => (
                <TabPanel
                  key={`TabPanel-${habitacion.espacio}-${i}`}
                  className="w-full"
                >
                  <HabitacionTab
                    habitacion={habitacion}
                    seeProductDetails={handleButtonClick}
                    preValues={preselection[habitacion.id]}
                  />
                </TabPanel>
              ))}
            </Tabs>
          )}
        </div>
      </div>
      <div className="text-right mt-8 mb-4">
        {PTPIsLoading ? (
          <div className="flex items-center">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
            <span>Enviando información...</span>
          </div>
        ) : canDownloadFiles ? (
          <Button
            text="Descargar Cotización"
            type={TypesButton.Button}
            classes="bg-primary text-white"
            onClick={cotizacionDownloader}
            disabled={loading}
          />
        ) : (
          <Button
            text="Guardar productos al proyecto"
            type={TypesButton.Button}
            classes="bg-primary text-white"
            onClick={() => setModalFormOpen(true)}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ProyectosDetail);
