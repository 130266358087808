import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userLogged from "../store/dto/userloged.dto";

export const useGetToken = () => {
  const [token, setToken] = useState<string>();
  const [user, setUser] = useState<userLogged>();

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (session && typeof session === "string") {
      const sessionObject = JSON.parse(session);
      if (sessionObject?.access_token) setToken(sessionObject?.access_token);
      if (sessionObject?.user) setUser(sessionObject?.user);
    }
  }, []);

  return { token, user };
};

export const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const session = localStorage.getItem("session");
    if (!session && typeof session !== "string") {
      navigate("/auth/login");
    }
  }, [navigate]);
};
