import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import useQuery from "../../hooks/useQuery";
import Button from "../../components/Button";
import { TypesButton } from "../../components/types.dto";
import { newPasswordSetter } from "../../constans/login";

const Recovery = () => {
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const query = useQuery();
  const token = query.get("token");

  return (
    <div className="max-w-lg mx-auto my-10 bg-gray-100 p-8 rounded-xl shadow shadow-slate-300">
      <ToastContainer />
      <h1 className="text-4xl font-medium">Nueva contraseña</h1>
      <p className="text-slate-500">
        Ingresa la nueva contraseña, en caso de éxito se te va a redirigir a la
        página de login
      </p>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (token && pass1 === pass2) await newPasswordSetter(pass1, token);
          else
            toast.error("Las contraseñas no coinciden", {
              position: toast.POSITION.TOP_CENTER,
            });
        }}
        className="my-10"
      >
        <div className="flex flex-col space-y-5">
          <label htmlFor="password">
            <p className="font-medium text-slate-700 pb-2">Contraseña</p>
            <input
              id="password"
              name="password"
              type="password"
              value={pass1}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Ejemplo: micorreo@mail.com"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPass1(e.target.value)
              }
            />
          </label>
          <label htmlFor="passwordTwo">
            <p className="font-medium text-slate-700 pb-2">
              Repite la contraseña
            </p>
            <input
              id="passwordTwo"
              name="passwordTwo"
              type="password"
              value={pass2}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Ejemplo: micorreo@mail.com"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPass2(e.target.value)
              }
            />
          </label>

          <Button
            type={TypesButton.Submit}
            text="Reestablecer contraseña"
            classes="bg-primary text-white my-4 w-full"
          />
          {/* <Link
            to="/auth/login"
            className="text-indigo-600 font-medium inline-flex space-x-1 items-center"
          >
            Cancelar y regresar a la pantalla de inicio
          </Link> */}
        </div>
      </form>
    </div>
  );
};

export default Recovery;
