import { useCallback, useEffect, useState } from "react";
import SchemaForm from "../../components/FormsJson/SchemaForm";
import { todayDay } from "../../constans/utils";

import productos from "../../assets/json/producto.json";
import { useSelector, useDispatch } from "react-redux";
import {
  addDataToProductModel,
  addNewColor,
  getProductModel,
  removeColor,
  resetToInitialState,
} from "../../store/productoSlice";
import ProductApiObject from "../../store/dto/producto.dto";
import {
  useGetDataForSelectsQuery,
  useSetFileToProductMutation,
  useSetNewProductoMutation,
} from "../../store/morhanaApi";
import { TypesButton } from "../../components/types.dto";
import Button from "../../components/Button";
import { cloneDeep, isNumber, isString } from "lodash";
import SchemaColor from "../../components/FormsJson/SchemaColor";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import InputImage from "../../components/InputImage";

const NuevoProducto = () => {
  const [image, setImage] = useState(null);
  const [jsonProductos, setJsonProductos] = useState(productos);

  const localModel: ProductApiObject = useSelector(getProductModel);
  const dispatch = useDispatch();
  const [fetcher, { data: newProduct, isLoading, isError, isSuccess }] =
    useSetNewProductoMutation();
  const [
    imageFetcher,
    { isLoading: isIFLoading, isError: isIFError, isSuccess: isIFSuccess },
  ] = useSetFileToProductMutation();
  const { data } = useGetDataForSelectsQuery("");

  useEffect(() => {
    if (isError || isIFError) {
      toast.error(
        "Ocurrió un error al guardar el nuevo producto, verifica la información",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [isError, isIFError]);

  useEffect(() => {
    if (isIFSuccess) {
      toast.success("Se envió la imágen información con éxito", {
        position: toast.POSITION.TOP_CENTER,
      });
      setImage(null);
      dispatch(resetToInitialState());
    }
  }, [dispatch, isIFSuccess]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Se envió la información del producto con éxito", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && image && newProduct?.uuid) {
      imageFetcher({ file: image, uuid: newProduct?.uuid }).unwrap();
    }
  }, [isSuccess, image, imageFetcher, newProduct?.uuid]);

  const dataSetterHandler = useCallback(
    (key: string, inputValue: String) => {
      dispatch(
        addDataToProductModel({
          key,
          data: inputValue,
        })
      );
    },
    [dispatch]
  );

  const colorHandler = useCallback(
    (_key: string, inputValue: any) => {
      if (isString(inputValue)) {
        dispatch(addNewColor(inputValue));
      } else if (isNumber(inputValue)) {
        dispatch(removeColor(inputValue));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      data &&
      Array.isArray(data?.familia_inmueble) &&
      jsonProductos.properties.familia_inmueble_id.enum.length === 0
    ) {
      const json: any = cloneDeep(jsonProductos);
      json.properties.familia_inmueble_id.enum = data?.familia_inmueble.map(
        ({ id, nombre }: any) => {
          return {
            value: id,
            label: nombre,
          };
        }
      );
      setJsonProductos(json);
    }
  }, [data, jsonProductos]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data?.proveedores) &&
      jsonProductos.properties.proveedor_id.enum.length === 0
    ) {
      const json: any = cloneDeep(jsonProductos);
      json.properties.proveedor_id.enum = data?.proveedores.map(
        ({ id, nombre }: any) => {
          return {
            value: id,
            label: nombre,
          };
        }
      );
      setJsonProductos(json);
    }
  }, [data, jsonProductos]);

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <ToastContainer />
      <Link to="/" className="mr-auto color-red-600">
        Regresar
      </Link>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await fetcher(localModel).unwrap();
        }}
      >
        <div className="flex flex-row items-center mb-4">
          <h1 className="mb-6 text-xl mr-auto font-bold">Nuevo producto</h1>
          <div className="ml-auto mr-2">Fecha de registro</div>
          <div className="custom-btn bg-white text-gray-900">{todayDay}</div>
        </div>
        <section className="bg-white px-6 py-4 rounded-lg">
          <SchemaForm
            schema={jsonProductos}
            customModelValues={localModel}
            customModelSetter={dataSetterHandler}
            classNames="grid md:grid-cols-4 grid-cols-1 grid-flow-row gap-4"
          />
          <div className="my-4">
            <SchemaColor
              schema={{
                title: "Color(es)",
                type: "string",
                widget: {
                  type: "SchemaColor",
                  props: {
                    required: true,
                  },
                },
              }}
              value={localModel.color}
              nameForm="color"
              modelSetter={colorHandler}
            />
          </div>
          <div className="my-6">
            <InputImage modelSetter={setImage} />
          </div>
          <div className="text-center mt-8 mb-4">
            {isLoading || isIFLoading ? (
              <div className="flex items-center">
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
                <span>Enviando información... no cierre la ventana</span>
              </div>
            ) : (
              <Button
                text="Aceptar"
                type={TypesButton.Submit}
                classes="bg-primary text-white"
              />
            )}
          </div>
        </section>
      </form>
    </div>
  );
};

export default NuevoProducto;
