import CountUp from "react-countup";

interface StickyBarProps {
  total: number;
  budget: number;
}

const StickyBar = ({ total, budget }: StickyBarProps) => {
  const budgetFormatted = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(budget);
  return (
    <div
      id="bottom-banner"
      className="fixed bottom-0 left-0 z-50 flex justify-between w-full p-4 border-t border-gray-200 bg-gray-50"
    >
      <div className="flex items-center mx-auto">
        <p className="flex items-center text-sm font-normal text-gray-500">
          {budget > 0 && (
            <div className="mr-4">
              <small>Presupuesto:</small> <strong>{budgetFormatted}</strong>
            </div>
          )}
          <div>
            <small>Precio final:</small>{" "}
            <strong className={total > budget ? "text-red-600" : "text-normal"}>
              <CountUp
                end={total}
                duration={1}
                formattingFn={(value: number) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(value)
                }
              />
            </strong>
          </div>
        </p>
      </div>
    </div>
  );
};

export default StickyBar;
