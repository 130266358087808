import { createBrowserRouter } from "react-router-dom";

import DefaultLayout from "../layout/Default";

// Proyectos Components
import ProyectosNew from "../screens/Proyectos/ProyectosNew";
import ProyectosDetail from "../screens/Proyectos/ProyectoDetail";
// Búsqueda Components
import Busqueda from "../screens/Busqueda/index";
// Espacios Components
import Espacios from "../screens/Espacios";
import EspacioDetalle from "../screens/Espacios/EspacioDetalle";
import EspaciosForm from "../screens/Espacios/EspaciosForm";
import EspacioProductos from "../screens/Espacios/EspacioProductos";
// Levantamientos components
import Levantamiento from "../screens/Levantamiento";
// Clientes Components
import Clientes from "../screens/Clientes";
import NuevoProducto from "../screens/Busqueda/NuevoProducto";
// Error Page
import ErrorPage from "../screens/Error404";
// Auth Pages
import Login from "../screens/Auth/Login";
import Forgot from "../screens/Auth/Forgot";
import Recovery from "../screens/Auth/Recovery";
// Profile
import Profile from "../screens/Profile";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Busqueda />,
      },
    ],
  },
  {
    path: "profile",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Profile />,
      },
    ],
  },
  {
    path: "auth",
    children: [
      {
        index: true,
        path: "login",
        element: <Login />,
      },
      {
        path: "contrasena",
        element: <Forgot />,
      },
      {
        path: "recovery",
        element: <Recovery />,
      },
    ],
  },
  {
    path: "productos",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        path: "nuevo",
        element: <NuevoProducto />,
      },
      {
        path: "editar",
        element: <NuevoProducto />,
      },
    ],
  },
  {
    path: "proyecto",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <ProyectosNew />,
      },
      {
        path: "detalle",
        element: <ProyectosDetail />,
      },
    ],
  },
  {
    path: "espacios",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Espacios />,
      },
      {
        path: "espacio",
        element: <EspacioDetalle />,
      },
      {
        path: "nuevo",
        element: <EspaciosForm />,
      },
      {
        path: "productos",
        element: <EspacioProductos />,
      },
    ],
  },
  {
    path: "levantamientos",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Levantamiento />,
      },
    ],
  },
  {
    path: "clientes",
    element: <DefaultLayout />,
    children: [
      {
        index: true,
        element: <Clientes />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);
