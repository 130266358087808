import Button from "../Button";
import { TypesButton } from "../types.dto";

interface SchemaArrayObjectsProps {
  customModelValues: any;
  customModelSetter: (key: string, inputValue: String, i: number) => void;
  newElementToArray: () => void;
  deleteElementofArray: (index: number) => void;
}

const ChildrenArray = ({
  customModelValues,
  customModelSetter,
  newElementToArray,
  deleteElementofArray,
}: SchemaArrayObjectsProps) => {
  return (
    <div className="schema-input flex flex-col">
      {customModelValues.map((o: any, i: number) => (
        <div
          key={`form_hijos_${i}`}
          className={`flex md:flex-row flex-col items-center`}
        >
          <div className={`flex flex-col md:mx-2 md:my-0 m-2 flex-1 w-full`}>
            <label>Género</label>
            <select
              value={customModelValues[i]?.genero}
              placeholder="seleccione..."
              className="input-style"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                customModelSetter("genero", e.target.value, i);
              }}
            >
              <option disabled value="">
                Seleccione...
              </option>
              <option value="masculino">Masculino</option>
              <option value="femenino">Femenino</option>
            </select>
          </div>
          <div className={`flex flex-col md:mx-2 md:my-0 m-2 flex-1 w-full`}>
            <label className="schema-input__label">¿Edad?</label>
            <input
              value={customModelValues[i]?.edad}
              className="input-style"
              placeholder="¿Edad?"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                customModelSetter("edad", e.target.value, i)
              }
            />
          </div>
          <button
            onClick={() => deleteElementofArray(i)}
            className="flex items-center"
            type="button"
          >
            <div className="w-6 h-6 text-white bg-red-600 flex items-center justify-center rounded-full mr-2">
              ✖
            </div>
            <span>Eliminar registro</span>
          </button>
        </div>
      ))}
      <div className="w-full text-center">
        <Button
          type={TypesButton.Button}
          onClick={newElementToArray}
          text="Agregar nuevo"
          classes="bg-gray-700 text-white w-64 ml-auto"
        />
      </div>
    </div>
  );
};
export default ChildrenArray;
