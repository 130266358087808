import { useState, useEffect } from "react";

interface InputProps {
  getValueFromInput: (searchText: string) => void;
  placehoder: string;
  value: string | number;
  otherProps?: any;
}

const InputMoney = ({
  getValueFromInput,
  placehoder,
  value,
  otherProps,
}: InputProps) => {
  const [localValue, setLocalValue] = useState("");
  useEffect(() => {
    setLocalValue(String(value));
  }, [value]);

  return (
    <div className="flex flex-row input-style items-center">
      <span className="mr-4">$</span>
      <input
        id="search"
        type="number"
        defaultValue={localValue}
        placeholder={placehoder}
        {...otherProps}
        className="border-0 w-full focus:outline-none text-right"
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
          getValueFromInput(e.target.value)
        }
      />
    </div>
  );
};

export default InputMoney;
