import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-modal";

import { useLazyGetClientsQuery } from "../../store/morhanaApi";
import { ProyectoDataBase } from "../../store/dto/proyectoDetails.dto";
import { Link } from "react-router-dom";

const Clientes: React.FC = () => {
  const [proyectos, setProyectos] = useState<ProyectoDataBase[]>();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchTrigger, { data, isLoading, isFetching }] =
    useLazyGetClientsQuery();

  useEffect(() => {
    searchTrigger("").unwrap();
  }, [searchTrigger]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const columns = [
    // {
    //   name: "Proyecto",
    //   selector: (row: RowData) => row.title,
    // },
    {
      name: "Cliente",
      selector: (row: any) => row.nombre,
    },
    {
      name: "Telefono",
      selector: (row: any) => row.telefono,
    },
    {
      name: "Correo",
      selector: (row: any) => row.email,
    },
    {
      cell: (row: any) =>
        row.Proyectos.length > 0 ? (
          <button
            type="button"
            className="px-4 py-2 text-black w-32"
            onClick={() => {
              console.log(row);
              setProyectos([...row.Proyectos]);
              setIsOpen(true);
            }}
          >
            Ver proyectos
          </button>
        ) : (
          <small>Sin proyectos</small>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  console.log(proyectos);

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <h2 className="mr-auto text-2xl">Proyectos:</h2>
            <button onClick={closeModal}>
              <strong className="text-xl align-center cursor-pointer alert-del">
                &times;
              </strong>
            </button>
          </div>
          <div className="mt-4 grid md:grid-cols-4 grid-cols-2 grid-flow-row gap-4">
            {(proyectos ?? []).map((proyecto) => (
              <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-lg transition ease-in-out p-3">
                <div className="flex flex-col">
                  <div>
                    <strong>Proyecto:</strong> {proyecto.nombre}
                  </div>
                  <div>
                    <strong>Estílo:</strong> {proyecto.estilo || "N/A"}
                  </div>
                  <div>
                    <strong>Budget:</strong>{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(proyecto.budget)}
                  </div>
                  <div className="mt-2">
                    <Link to={`/proyecto/detalle?uuid=${proyecto.uuid}`}>
                      Ver detalle del proyecto
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <h1 className="mb-6 text-xl mr-auto font-bold">Clientes</h1>
      <div className="flex flex-col bg-white rounded-lg p-4">
        <div className="flex my-3"></div>
        <DataTable
          columns={columns}
          data={data || []}
          progressPending={isLoading || isFetching}
          pagination
        />
      </div>
    </div>
  );
};

export default Clientes;
