import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import { EspacioStateSpaces } from "./dto/espacio.dto";
import { ActionSlice } from "./dto/store.dto";

const initialState: EspacioStateSpaces = {
  nombre: "",
  medidas_espacios: [],
  plano: null,
};

export const espacioSlice = createSlice({
  name: "espacio",
  initialState,
  reducers: {
    addDataToModel: (state: any, action: ActionSlice) => {
      state[action.payload.key as any] = action.payload.data;
    },
    setOverallModel: (state: EspacioStateSpaces, action) => {
      state = action.payload;
    },
    setDataSpaceToModel: (state: EspacioStateSpaces, action) => {
      set(state.medidas_espacios, action.payload.key, action.payload.data);
    },
    addNewDataSpacetoModel: (state: EspacioStateSpaces) => {
      // @ts-ignore
      state.medidas_espacios.push({
        largo: "",
        ancho: "",
        alto: "",
        metros_cuadrados: "",
        espacio: "",
        prototipo: "",
      });
    },
    deleteDataSpacetoModel: (state: EspacioStateSpaces, action) => {
      // @ts-ignore
      const filtered = state.medidas_espacios.filter(
        (_o: any, index: number) => index !== action.payload
      );
      // @ts-ignore
      state.medidas_espacios = filtered;
    },
  },
});

export const getEspacioModel = (state: any) => state.espacio;

export const processListenerMiddleware = createListenerMiddleware();

export const {
  addDataToModel,
  setOverallModel,
  setDataSpaceToModel,
  addNewDataSpacetoModel,
  deleteDataSpacetoModel,
} = espacioSlice.actions;

export default espacioSlice.reducer;
