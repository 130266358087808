import { useState, useEffect, lazy, Suspense, memo } from "react";
import { get } from "lodash";

interface SchemaFormStructure {
  title: String;
  description: String;
  type: String;
  properties: any;
}

interface SchemaFormProps {
  schema: SchemaFormStructure;
  customModelValues: any;
  customModelSetter: (key: string, inputValue: String) => void;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
  classNames?: string;
}

const SchemaForm = ({
  schema,
  fallback = <p>Cargando...</p>,
  classNames = "grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4",
  // El custom setter es porque actualmente se está usando en proceso de opción pero como busco usar el mismo compomente en otros módulos, entonces este prop permite usar tu propio reducer...
  customModelSetter,
  // el custom model values es para mostrar la información...
  customModelValues,
  children,
}: SchemaFormProps) => {
  const [components, SetComponents]: any = useState([]);
  const [fieldNames] = useState(Object.keys(schema.properties || {}));

  useEffect(() => {
    async function loadComponentsBySchema() {
      const componentPromises = fieldNames.map(async (key, i) => {
        // Obtenemos el tipo de componente a renderizar, si deseas agregar otro integralo en FoormsJson
        const compBySchema = get(
          schema,
          `properties[${key}].widget.type`,
          "SchemaInput"
        );
        const data = get(schema, `properties[${key}]`, {});

        // Importamos con lazy...
        const DynComponent = lazy(
          () => import(`${__dirname}/${compBySchema}.tsx`)
        );
        // Renderizamos e integramos modelSetter, el cual está conectado a nuestro sexy reducer, recuerda que el nuevo input debe de indicar en sus eventos el enviar "name" y "value" del campo.

        return (
          <DynComponent
            key={`comp_${key}_${i}`}
            nameForm={key}
            schema={data}
            value={customModelValues[key]}
            modelSetter={customModelSetter}
          />
        );
      });

      Promise.all(componentPromises).then(SetComponents);
    }

    loadComponentsBySchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  return (
    <>
      <h2 className="mt-4 mb-2 text-xl font-semibold">{schema.title}</h2>
      <div className={classNames}>
        <Suspense fallback={fallback}>{components}</Suspense>
        {children}
      </div>
    </>
  );
};

export default memo(SchemaForm);
