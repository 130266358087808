import { memo, useEffect, useRef, useState } from "react";
import Button from "./Button";

const InputImage = ({
  imageUrl,
  modelSetter,
}: {
  imageUrl?: string;
  modelSetter: (file: any) => void;
}) => {
  const inputRef = useRef<any>(null);
  const [file, setfile] = useState<any>();
  const [localUrl, setLocalUrl] = useState<any>();

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    console.log(file);
    if (file && file.length > 0) {
      modelSetter(file[0]);
      setfile(file[0]);
    }
  };

  useEffect(() => {
    if (file) {
      var reader = new FileReader();
      reader.onload = function (e: any) {
        setLocalUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <div className="w-full h-full bg-bg-app">
      <label
        style={{ backgroundImage: `url('${imageUrl ?? localUrl}')` }}
        className="flex justify-center items-center w-full h-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none bg-center bg-no-repeat py-4"
      >
        <div className="flex flex-col items-center space-x-2">
          <span className="font-bold">Adjuntar imágen</span>
          <span className="font-light">
            Click al botón para adjuntar imágen
          </span>
          <Button
            text={file?.name || "Seleccionar archivos"}
            classes="bg-primary text-white"
            onClick={() => inputRef?.current?.click()}
          />
        </div>
        <input
          ref={inputRef}
          type="file"
          name="file_upload"
          className="hidden"
          onChange={fileHandler}
        />
      </label>
    </div>
  );
};

export default memo(InputImage);
