import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import { HabitacionSliceProps } from "./dto/habitacion.dto";
import { ActionSlice } from "./dto/store.dto";

const initialState: HabitacionSliceProps = {
  espacio: "",
  unidad_medida: "",
  ancho: "",
  alto: "",
  largo: "",
  metros_cuadrados: "",
};

export const habitacionesSlice = createSlice({
  name: "habitacion",
  initialState,
  reducers: {
    addDataToProductModel: (state: any, action: ActionSlice) => {
      state[action.payload.key as any] = action.payload.data;
    },
  },
});

export const getHabitacionModel = (state: any) => state.habitacion;

export const processListenerMiddleware = createListenerMiddleware();

export const { addDataToProductModel } = habitacionesSlice.actions;

export default habitacionesSlice.reducer;
