import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ClienteObject,
  FamiliaObject,
  TipoProdApiPayload,
  UpdateModelInteface,
} from "./dto/api.dto";
import ProductApiObject from "./dto/producto.dto";
import {
  PayloadDevelopmentFiles,
  ProjectState,
  ProjectToProduct,
} from "./dto/project.dto";
import { ProyectoDataBase } from "./dto/proyectoDetails.dto";
import { BAKCEND_SERVER, isJson } from "../constans/utils";
import { ApiPototipoHabitaciones, EspaciosPayload } from "./dto/espacio.dto";
import { HabitacionProductosResponse } from "./dto/habitacion.dto";
import { FiltersPayload } from "./dto/filters.dto";

export const morhanaApi = createApi({
  reducerPath: "morhanaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BAKCEND_SERVER,

    prepareHeaders: async (headers, { endpoint }) => {
      // const state = JSON.parse(localStorage.getItem("state"));
      if (
        endpoint !== "setFiletoDesarrollo" &&
        endpoint !== "setFileToTypeOfDev" &&
        endpoint !== "setFileToProduct"
      ) {
        headers.set("Content-Type", "application/json");
        headers.set("ngrok-skip-browser-warning", "true");
      }

      const isjson = isJson(localStorage.getItem("session") || "");
      if (isjson) headers.set("Authorization", `Bearer ${isjson.access_token}`);
      // headers.set("Authorization", `Bearer ${state.token}`);
      return headers;
    },
  }),
  tagTypes: [
    "Clients",
    "Products",
    "TypesProd",
    "Proyecto",
    "Desarrollos",
    "Prototipos",
    "Selectors",
    "Familias",
  ],
  endpoints: (builder) => ({
    getClientByName: builder.query<ClienteObject, string>({
      query: (nombre: string) => {
        return {
          url: `clientes/${nombre}`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Clients", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getClients: builder.query<any[], string>({
      query: () => {
        return {
          url: `clientes`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Clients", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getFamiliasInmueble: builder.query<FamiliaObject[], null>({
      query: () => {
        return {
          url: `/familias-inmueble`,
          method: "GET",
        };
      },
      providesTags: (result, _error) => (result ? [{ type: "Familias" }] : []),
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getTypesOfProducts: builder.query<TipoProdApiPayload, string>({
      query: () => {
        return {
          url: `productos/tipoproducto`,
          method: "GET",
        };
      },
      // tipo_producto
      providesTags: (result, _error, id) =>
        result ? [{ type: "TypesProd", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getProductByName: builder.query<ProductApiObject[], string>({
      query: (nombre: string) => {
        return {
          url: `productos/${nombre}`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Products", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getAllProyects: builder.query<ProyectoDataBase[], string>({
      query: () => {
        return {
          url: `proyecto`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Proyecto", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    // getProyectDetails: builder.query<ProjectDetailsState, string>({
    //   query: (uuid: string) => {
    //     return {
    //       url: `productos/projecto/${uuid}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: (result, _error, id) =>
    //     result ? [{ type: "Proyecto", id: id }] : [],
    //   transformErrorResponse: (err) =>
    //     err.status === 401 && localStorage.removeItem("session"),
    // }),
    getProyectDetails: builder.query<ProyectoDataBase, string>({
      query: (uuid: string) => {
        return {
          url: `proyecto/${uuid}`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Proyecto", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getDesarrollos: builder.query<any[], string>({
      query: () => {
        return {
          url: `desarrollos`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Desarrollos", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getPrototipos: builder.query<ApiPototipoHabitaciones, string>({
      query: (id: string) => {
        return {
          url: `desarrollos/prototipos/${id}`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Prototipos", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getDataForSelects: builder.query<any, string>({
      query: () => {
        return {
          url: `productos/selectors`,
          method: "GET",
        };
      },
      providesTags: (result, _error, id) =>
        result ? [{ type: "Selectors", id: id }] : [],
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getAiImagesToProyecto: builder.query<any, string>({
      query: (uuid: string) => {
        return {
          url: `proyecto/aiimages/${uuid}`,
          method: "GET",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    getProductsByRoom: builder.query<
      HabitacionProductosResponse,
      { id: string | number; endpoint: string }
    >({
      query: ({ id, endpoint }: { id: string | number; endpoint: string }) => {
        return {
          url: `/${endpoint}/productos/${id}`,
          method: "GET",
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setProductsToProyect: builder.mutation<any, ProjectToProduct>({
      query: (payload: ProjectToProduct) => {
        return {
          url: `proyecto/productos/${payload.relation}/${payload.uuid}`,
          method: "POST",
          body: JSON.stringify({
            productos: payload.productos,
            datosFinales: payload.datosFinales,
          }),
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setNewProyecto: builder.mutation<any, ProjectState>({
      query: (payload: ProjectState) => {
        return {
          url: `proyecto/new`,
          method: "POST",
          body: JSON.stringify(payload),
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setNewEspacio: builder.mutation<any, EspaciosPayload>({
      query: (payload: EspaciosPayload) => {
        return {
          url: `desarrollos/new`,
          method: "POST",
          body: JSON.stringify(payload),
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setFiletoDesarrollo: builder.mutation<any, File | Blob>({
      query: (payload: File | Blob) => {
        const formData = new FormData();
        formData.append("file", payload);
        return {
          url: `docs/desarrollos`,
          method: "POST",
          body: formData,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setFileToProduct: builder.mutation<
      any,
      { file: File | Blob; uuid: string }
    >({
      query: (payload: { file: File | Blob; uuid: string }) => {
        const formData = new FormData();
        formData.append("image", payload.file);
        return {
          url: `/productos/imagenes/${payload.uuid}`,
          method: "POST",
          body: formData,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setFileToTypeOfDev: builder.mutation<any, PayloadDevelopmentFiles>({
      query: (payload: PayloadDevelopmentFiles) => {
        const formData = new FormData();
        formData.append("plano", payload.file);
        return {
          url: `/desarrollos/archivos/${payload.id}/${payload.type}`,
          method: "POST",
          body: formData,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setNewProducto: builder.mutation<any, ProductApiObject>({
      query: (payload: ProductApiObject) => {
        return {
          url: `productos/nuevo`,
          method: "POST",
          body: JSON.stringify(payload),
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setFiltersToProducts: builder.mutation<any, FiltersPayload>({
      query: (payload: FiltersPayload) => {
        return {
          url: `/productos/filtered`,
          method: "POST",
          body: JSON.stringify(payload),
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    updateModelOfDevelopments: builder.mutation<any, UpdateModelInteface>({
      query: ({ type, id, value }: UpdateModelInteface) => {
        return {
          url: `desarrollos/${type}/edit/${id}`,
          method: "PUT",
          body: value,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
    setVariable: builder.mutation<any, { variable: string | number }>({
      query: (payload: { variable: string | number }) => {
        return {
          url: `auth/edit/variable`,
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (err) =>
        err.status === 401 && localStorage.removeItem("session"),
    }),
  }),
});

export const {
  useLazyGetClientByNameQuery,
  useLazyGetClientsQuery,
  useLazyGetTypesOfProductsQuery,
  useLazyGetProductByNameQuery,
  useGetAllProyectsQuery,
  useGetFamiliasInmuebleQuery,
  useLazyGetProyectDetailsQuery,
  useLazyGetDesarrollosQuery,
  useLazyGetPrototiposQuery,
  useLazyGetProductsByRoomQuery,
  useGetDataForSelectsQuery,
  useSetNewProyectoMutation,
  useSetNewEspacioMutation,
  useLazyGetAiImagesToProyectoQuery,
  useSetFiltersToProductsMutation,
  useSetProductsToProyectMutation,
  useSetFiletoDesarrolloMutation,
  useSetFileToProductMutation,
  useSetNewProductoMutation,
  useUpdateModelOfDevelopmentsMutation,
  useSetFileToTypeOfDevMutation,
  useSetVariableMutation,
} = morhanaApi;
