import busqueda from "./busqueda.svg";
import busquedaW from "./busqueda_w.svg";
import clientes from "./clientes.svg";
import espacios from "./espacios.svg";
import levantamiento from "./levantamiento.svg";
import nuevo_proy from "./nuevo_proy.svg";
import wheelchair from "./wheelchair.svg";
import eye from "./eye.svg";
import ear from "./ear.svg";
import movility from "./movility.svg";
import cintaMetrica from "./cinta-metrica.svg";
import cama from "./cama.svg";
import inodoro from "./inodoro.svg";
import buildings from "./buildings.svg";
import update from "./update.svg";
import dbicon from "./db-icon.png";

export {
  busqueda,
  clientes,
  espacios,
  levantamiento,
  nuevo_proy,
  wheelchair,
  eye,
  ear,
  movility,
  busquedaW,
  cintaMetrica,
  cama,
  inodoro,
  buildings,
  update,
  dbicon,
};
