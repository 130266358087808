import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-range-slider-input/dist/style.css";
import reportWebVitals from "./reportWebVitals";

import { router } from "./routes/index";
import { RouterProvider } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store/index";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

if (process.env.NODE_ENV !== "production") {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
