import { useState } from "react";
import { JsonSchemaGlobalInterface } from "./types.dto";

import { get } from "lodash";
import { SketchPicker } from "react-color";
// import colores from "../../assets/json/colors.json";

const SchemaColor = ({
  schema,
  nameForm,
  value,
  modelSetter,
}: JsonSchemaGlobalInterface) => {
  const widgetProps = get(schema, "widget.props");
  const [localValue, setLocalValue] = useState("");
  const [toggle, setToggle] = useState(false);

  return (
    <div
      id={`schema-${nameForm}`}
      className="schema-input flex flex-col relative"
    >
      <label className="schema-input__label" htmlFor={nameForm}>
        {schema.title} {widgetProps.required && " *"}
      </label>
      <button
        type="button"
        className="input-style flex flex-row items-center"
        onClick={() => setToggle(!toggle)}
      >
        <span className="mr-auto">{schema.title}</span>
        <div className="ml-auto flex flex-row">
          {Array.isArray(value) &&
            value.map((v: string, i: number) => (
              <div
                key={`color_${i}_${v}`}
                className="h-3 w-3 rounded-full  m-1"
                style={{ backgroundColor: `#${v}` }}
              />
            ))}
        </div>
      </button>
      {toggle && (
        <div
          className="flex flex-col bg-white shadow absolute left-0 
        top-20 z-20"
        >
          <div className="flex">
            <SketchPicker
              color={localValue}
              onSwatchHover={({ hex }) => {
                setLocalValue(hex.replace("#", ""));
              }}
              onChangeComplete={({ hex }) => {
                setLocalValue(hex.replace("#", ""));
              }}
            />
            <div className="flex flex-col items-center w-12 mx-auto">
              {Array.isArray(value) &&
                value.map((v: string, i: number) => (
                  <button
                    key={`color_${i}_${v}`}
                    type="button"
                    className="h-6 w-6 rounded-full m-1 border border-gray-100"
                    style={{ backgroundColor: `#${v}` }}
                    onClick={() => modelSetter(nameForm, i)}
                  />
                ))}
            </div>
          </div>
          <button
            type="button"
            className="w-full text-center py-3 bg-primary text-white"
            onClick={() => modelSetter(nameForm, localValue.replace("#", ""))}
          >
            Agregar color
          </button>
        </div>
      )}
      <small className="schema-input__help">{schema.description}</small>
    </div>
  );
};
export default SchemaColor;
