import { useState } from "react";
import ProductApiObject from "../store/dto/producto.dto";

const InputCounter = ({
  storeValue,
  row,
  handleChange,
}: {
  storeValue: any;
  row: ProductApiObject;
  handleChange: ({
    producto,
    cantidad,
  }: {
    producto: ProductApiObject;
    cantidad: number;
  }) => void;
}) => {
  const [value, setVaule] = useState(storeValue?.cantidad ?? 1);
  return (
    <div className="flex flex-col items-center">
      <div className="custom-number-input w-32">
        <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
          <button
            data-action="decrement"
            type="button"
            className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
            onClick={() => setVaule((v: any) => (v - 1 <= 0 ? 1 : v - 1))}
          >
            <span className="m-auto text-2xl font-thin">−</span>
          </button>
          <input
            type="number"
            className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
            name="custom-input-number"
            value={value}
            readOnly
          />
          <button
            data-action="increment"
            type="button"
            className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
            onClick={() => setVaule((v: any) => v + 1)}
          >
            <span className="m-auto text-2xl font-thin">+</span>
          </button>
        </div>
      </div>
      <div className="flex mt-2">
        <button
          type="button"
          className="h-full w-20 p-2 rounded cursor-pointer bg-primary text-white"
          onClick={() => handleChange({ producto: row, cantidad: value })}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default InputCounter;
