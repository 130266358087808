import { orderBy } from "lodash";
import { wheelchair, eye, ear, movility } from "../assets/icons/index";

export const BAKCEND_SERVER =
  process.env.REACT_APP_BACKEND_URL ?? "https://api.morhada.com.mx";

export const todayDay = new Date().toISOString().slice(0, 10);

export const ASISTENCIAS = [
  { label: "Silla de ruedas", icon: wheelchair, name: "silla_ruedas" },
  { label: "Apoyo visual", icon: eye, name: "apoyo_visual" },
  { label: "Apoyo  auditivo", icon: ear, name: "apoyo_auditivo" },
  { label: "Movilidad", icon: movility, name: "movilidad" },
];

export const EXTRAS_ASISTENCIAS = [{ name: "" }];

const arrayEverything = [
  { label: "Clientes", value: "clientes" },
  { label: "Familiares", value: "familiares" },
  { label: "Todas las anteriores", value: "todas" },
  { label: "Turista", value: "turista" },
  { label: "Empresario", value: "empresario" },
  { label: "Estudiante", value: "estudiante" },
  { label: "Familiares", value: "familiares" },
  { label: "Soltero", value: "soltero" },
  { label: "Soltera", value: "soltera" },
  { label: "Con hijos", value: "con_hijos" },
  { label: "Otro", value: "otro" },
  { label: "Personal", value: "personal" },
  { label: "Personal + Clientes", value: "personal_clientes" },
  {
    label: "Personal + Clientes + Capacitación",
    value: "personal_clientes_capacitacion",
  },
  { label: "Comida rápida", value: "comida_rapida" },
  { label: "Ordinario", value: "ordinario" },
  { label: "Premium", value: "premium" },
];

export const DROPLIST = {
  renta_fija: [
    { label: "Clientes", value: "clientes" },
    { label: "Familiares", value: "familiares" },
    { label: "Todas las anteriores", value: "todas" },
  ],
  corta_estancia: [
    { label: "Turista", value: "turista" },
    { label: "Empresario", value: "empresario" },
    { label: "Estudiante", value: "estudiante" },
    { label: "Familiares", value: "familiares" },
  ],
  habitacion: [
    { label: "Soltero", value: "soltero" },
    { label: "Soltera", value: "soltera" },
    { label: "Con hijos", value: "con_hijos" },
    { label: "Otro", value: "otro" },
  ],
  remodelacion: arrayEverything,
  construccion: arrayEverything,
  oficinas: [
    { label: "Personal", value: "personal" },
    { label: "Personal + Clientes", value: "personal_clientes" },
    {
      label: "Personal + Clientes + Capacitación",
      value: "personal_clientes_capacitacion",
    },
  ],
  restaurante: [
    { label: "Comida rápida", value: "comida_rapida" },
    { label: "Ordinario", value: "ordinario" },
    { label: "Premium", value: "premium" },
  ],
};

export const USERKEYS = [
  "rfc",
  "telefono",
  "email",
  "calle",
  "cp",
  "estado",
  "ciudad",
];

export const isJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return false;
  }
};

export const imageValidator = (url: string) => {
  if (!url) return "/no-image.png";
  return url.includes("https") ||
    url.includes("http") ||
    [".svg", ".png", ".jpg", ".jpeg"].includes(url)
    ? url
    : "/no-image.png";
};

export const removeDuplicates = (a: any[], b: any[], key: string) => {
  return b.filter(function (item) {
    for (let i = 0, len = a.length; i < len; i++) {
      if (a[i][key] === item[key]) {
        return true;
      }
    }
    return false;
  });
};

export const whiteSpacesRemover = (string: string) =>
  string.replace(/^[ ]+/g, "").replace(/[ ]+$/g, "");

export const customOrder = (collectionRooms: any) =>
  orderBy(
    collectionRooms,
    [
      (o) => o.espacio.includes("Sala"),
      (o) => o.espacio.includes("Comedor"),
      "espacio",
    ],
    ["desc", "desc", "asc"]
  );

export const formatToMoney = (value: number) =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(value);

export const getDiffDays = (fd: string, secondDate: any) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate: any = new Date(fd);

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};
