import { BAKCEND_SERVER } from "./utils";

const base = BAKCEND_SERVER;

interface HedaersApiClient {
  [key: string]: string;
}

const headers: HedaersApiClient = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "ngrok-skip-browser-warning": "true",
};

export default class Api {
  http: Http;
  constructor() {
    this.http = new Http(base);
  }

  get base() {
    return this.http.base;
  }
}

class Http {
  _base: any;
  constructor(base: string) {
    this._base = base;
  }
  get base() {
    return this._base;
  }

  async get(url: string, token = "", options = {}) {
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    const res = await fetch(`${this.base}/${url}`, {
      ...options,
      method: "GET",
      headers: headers,
    });
    const data = await res.json();
    return data;
  }

  async post(url: string, body: any, token = "", options = {}) {
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    const res = await fetch(`${this.base}/${url}`, {
      ...options,
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    const data = await res.json();
    return data;
  }

  async getStream(url: string, token = "", options = {}) {
    if (token) {
      headers.authorization = `Bearer ${token}`;
    }
    const res = await fetch(`${this.base}/${url}`, {
      ...options,
      method: "GET",
      headers: headers,
    });
    const data = await res.blob();
    return data;
  }
}
