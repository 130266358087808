import DataTable from "react-data-table-component";

import { useGetAllProyectsQuery } from "../../store/morhanaApi";
import { ProyectoDataBase } from "../../store/dto/proyectoDetails.dto";
import { Link } from "react-router-dom";

const Levantamiento: React.FC = () => {
  const { data, isLoading, isFetching } = useGetAllProyectsQuery("");

  const columns = [
    {
      name: "Proyecto",
      selector: (row: ProyectoDataBase) => row.nombre,
    },
    {
      name: "Cliente",
      selector: (row: ProyectoDataBase) => row?.fk_cliente?.nombre || "",
      sortable: true,
    },
    {
      name: "Budget",
      selector: (row: ProyectoDataBase) => row.budget,
      format: (row: ProyectoDataBase) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(row.budget),
      sortable: true,
    },
    {
      name: "Correo",
      selector: (row: ProyectoDataBase) => row?.fk_cliente?.email || "",
    },
    {
      name: "Estílo",
      selector: (row: ProyectoDataBase) => row.estilo || "N/A",
      sortable: true,
    },
    {
      cell: (row: ProyectoDataBase) => (
        <Link to={`/proyecto/detalle?uuid=${row.uuid}`}>Ver detalle</Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <h1 className="mb-6 mb-6 text-xl mr-auto font-bold">Levantamientos</h1>
      <div className="flex flex-col bg-white rounded-lg p-4">
        <div className="flex my-3"></div>
        <DataTable
          columns={columns}
          data={data ?? []}
          progressPending={isLoading || isFetching}
          pagination
        />
      </div>
    </div>
  );
};

export default Levantamiento;
