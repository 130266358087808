import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import { ProjectState } from "./dto/project.dto";
import { set } from "lodash";
import { ActionObjectSlice, ActionSlice } from "./dto/store.dto";

const initialState: ProjectState = {
  uuid: "",
  budget: 0,
  plano: null,
  cliente: {
    proyecto: "",
    cliente: "",
    rfc: "",
    telefono: "",
    email: "",
    calle: "",
    cp: "",
    estado: "",
    ciudad: "",
    presupuesto: "",
  },
  necesidades: {
    razon: "",
    quien: "",
    expectativa_color: [],
    estilo: "",
    mascota: "false",
    hijos: [],
  },
  asistencias: {
    silla_ruedas: false,
    apoyo_visual: false,
    apoyo_auditivo: false,
    movilidad: false,
  },
  extras: {
    tapetes: false,
    lamparas: false,
    alfombra: false,
    exceso_zapatos: false,
    tapiz: false,
    articulos_decorativos: false,
    persianas: false,
    coleccionista: false,
    cortinas: false,
    espejos: false,
    plantas: "",
    electrodomesticos: [],
  },
  medidas_espacios: [],
  medidas_espacio_id: null,
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    addDataToModelObject: (state: ProjectState, action: ActionObjectSlice) => {
      //const localState = cloneDeep(state);
      const [keyName] = Object.keys(action.payload.data);
      const objectKey: string | any = action.payload.object;

      // @ts-ignore
      state[objectKey as keyof ProjectState] = {
        // @ts-ignore
        ...state[objectKey as keyof ProjectState],
        ...{ [keyName]: action.payload.data[keyName] },
      };
    },
    addDataToModel: (state: any, action: ActionSlice) => {
      state[action.payload.key as any] = action.payload.data;
    },
    setDataToChildrenNode: (state: ProjectState, action) => {
      set(
        state.necesidades.hijos[action.payload.index],
        action.payload.key,
        action.payload.data
      );
    },
    deleteDataFromChildren: (state: ProjectState, action) => {
      const filtered = state.necesidades.hijos.filter(
        (_o: any, index: number) => index !== action.payload
      );
      // @ts-ignore
      state.necesidades.hijos = filtered;
    },
    setNewTypeRoomOfMedidas: (state: ProjectState, action) => {
      state.medidas_espacios.push({
        largo: "",
        ancho: "",
        alto: "",
        metros_cuadrados: "",
        imagen_url: "",
        nombre_habitacion: "",
        id_familia_inmueble: action.payload,
      });
    },
    setDataSpaceToModel: (state: ProjectState, action) => {
      set(
        state.medidas_espacios[action.payload.index],
        action.payload.key,
        action.payload.data
      );
    },
    deleteDataSpacetoModel: (state: ProjectState, action) => {
      console.log(action.payload.index);
      const filtered = state.medidas_espacios.filter(
        (_o, index: number) => index !== action.payload.index
      );
      state.medidas_espacios = [...filtered];
    },
    fetchProcessError: (state: ProjectState, action) => {
      console.log("fetchProcessError: ", action.payload);
      //state.error = action.payload;
    },
    addNewColor: (state: ProjectState, action) => {
      const arr = state.necesidades.expectativa_color.slice();
      arr.push(action.payload);
      state.necesidades.expectativa_color = arr;
    },
    removeColor: (state: ProjectState, action) => {
      const arr = state.necesidades.expectativa_color.filter(
        (_c: string, index: number) => action.payload !== index
      );
      state.necesidades.expectativa_color = arr;
    },
    resetToInitialState: (state: ProjectState) => {
      state = initialState;
    },
  },
});

export const getProjectModel = (state: any) => state.project;

export const processListenerMiddleware = createListenerMiddleware();

export const {
  addDataToModelObject,
  addDataToModel,
  fetchProcessError,
  setDataSpaceToModel,
  setNewTypeRoomOfMedidas,
  deleteDataSpacetoModel,
  setDataToChildrenNode,
  deleteDataFromChildren,
  addNewColor,
  removeColor,
  resetToInitialState,
} = projectSlice.actions;

export default projectSlice.reducer;
