import { busquedaW } from "../assets/icons/index";

interface InputProps {
  width?: string;
  placeholder?: string;
  getSearchText: (searchText: string) => void;
  buttonClicked?: () => void;
}

const SearchInput = ({
  width,
  placeholder = "Buscar",
  getSearchText,
  buttonClicked,
}: InputProps) => {
  return (
    <div className={`flex flex-row input-style items-center ${width}`}>
      <input
        id="search"
        placeholder={placeholder}
        className="border-0 w-full focus:outline-none"
        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
          getSearchText(e.target.value)
        }
      />
      <button
        type="button"
        className="w-10 h-8 rounded-full bg-primary"
        onClick={buttonClicked}
      >
        <img alt="search icon" src={busquedaW} className="w-3 mx-auto" />
      </button>
    </div>
  );
};

export default SearchInput;
