import { createListenerMiddleware, createSlice } from "@reduxjs/toolkit";
import ProductApiObject from "./dto/producto.dto";
import { ActionSlice } from "./dto/store.dto";

const initialState: ProductApiObject = {
  uuid: "",
  codigo: "",
  nombre_completo: "",
  descripcion: "",
  imagen_url: "",
  marca: "",
  tipo_producto: "",
  proveedor_id: 0,
  familia_inmueble_id: 0,
  unidad: "",
  cantidad: 0,
  piezas_adicionales: 0,
  fichas_tecnicas: "",
  unidad_medida: "",
  precio: 0,
  ancho_1: 0,
  ancho_2: 0,
  alto_1: 0,
  alto_2: 0,
  largo_1: 0,
  largo_2: 0,
  estilo: "",
  profundidad_1: 0,
  profundidad_2: 0,
  diametro_1: 0,
  diametro_2: 0,
  material: "",
  color: [],
  tela: "",
  acabado: "",
  garantia_de_fabrica: "",
  garantia_de_instalacion: "",
  cobertura_de_entrega: "",
  modalidad_de_entrega: "",
  tiempo_de_entrega_fabricacion: "",
  disponibilidad: "",
  metodos_de_pago: "",
  otros: "",
  uso_exterior: false,
  uso_interior: false,
  activo: true,
};

export const productoSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addDataToProductModel: (state: any, action: ActionSlice) => {
      state[action.payload.key as any] = action.payload.data;
    },
    addNewColor: (state: any, action) => {
      const arr = state.color.slice();
      arr.push(action.payload);
      state.color = arr;
    },
    removeColor: (state: any, action) => {
      const arr = state.color.filter(
        (_c: string, index: number) => action.payload !== index
      );
      state.color = arr;
    },
    resetToInitialState: (state: ProductApiObject) => {
      state = initialState;
    },
  },
});

export const getProductModel = (state: any) => state.product;

export const processListenerMiddleware = createListenerMiddleware();

export const {
  addDataToProductModel,
  addNewColor,
  removeColor,
  resetToInitialState,
} = productoSlice.actions;

export default productoSlice.reducer;
