import { useState, useEffect, useMemo, memo } from "react";
import DataTable from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";

import { useLazyGetProductsByRoomQuery } from "../../store/morhanaApi";
import { SelectTwoType } from "../../store/dto/store.dto";
import ProductApiObject from "../../store/dto/producto.dto";
import { HabitacionProductoRelation } from "../../store/dto/habitacion.dto";
import {
  deleteProductoToHabitacion,
  getOverallHabitacionesProductos,
  setProductosToHabitacion,
} from "../../store/productosHabitacionSlice";
import InputCounter from "../InputCounter";
import { find } from "lodash";

type HabitacionProps = any;
interface HabitacionTabsProps {
  habitacion: HabitacionProps;
  seeProductDetails?: (productRow: ProductApiObject) => void;
  preValues?: HabitacionProductoRelation[];
}

const HabitacionTab = ({
  seeProductDetails,
  habitacion,
  preValues,
}: HabitacionTabsProps) => {
  const [isEditable, setIsEditable] = useState(true);
  const [filtros, setFiltros] = useState<SelectTwoType[]>([]);
  const [tiposFilters, setTiposFilters] = useState<SelectTwoType[]>([]);
  const [overallData, setoverallData] = useState<ProductApiObject[]>([]);

  const [fetcher, { data, isLoading, isFetching }] =
    useLazyGetProductsByRoomQuery();
  const dispatch = useDispatch();
  const overallRooms = useSelector(getOverallHabitacionesProductos);
  const localModel = useMemo(
    () => overallRooms[habitacion.id],
    [overallRooms, habitacion.id]
  );

  useEffect(() => {
    if (habitacion.id && habitacion.espacio) {
      fetcher({
        id: habitacion.id,
        endpoint: habitacion.proyecto_uuid
          ? "habitaciones-medidas"
          : "desarrollos",
      }).unwrap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habitacion.id]);

  useEffect(() => {
    if (data?.tipo_productos && Array.isArray(data?.tipo_productos)) {
      const maped = data?.tipo_productos.map((tp) => {
        return {
          label: tp,
          value: tp,
        };
      });
      setTiposFilters([...maped]);
    }
  }, [data?.tipo_productos]);

  useEffect(() => {
    if (
      preValues &&
      Array.isArray(preValues) &&
      data?.productos &&
      Array.isArray(data?.productos)
    ) {
      const arrOfPreValues = preValues.map(
        ({ producto_uuid }) => producto_uuid
      );
      const filtered = data?.productos.filter(({ uuid }) =>
        arrOfPreValues.includes(uuid)
      );
      setIsEditable(false);
      setoverallData(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preValues, data?.productos]);

  useEffect(() => {
    if (data?.productos && Array.isArray(data?.productos)) {
      const filters = filtros.map(({ value }) => value);
      setoverallData(
        data?.productos.filter((prod) => filters.includes(prod.tipo_producto))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros]);

  const handleChange = ({
    producto,
    cantidad,
  }: {
    producto: ProductApiObject;
    cantidad: number;
  }) => {
    if (isEditable === true) {
      // const uuids = selectedRows.map(({ uuid }: ProductApiObject) => uuid);
      dispatch(
        setProductosToHabitacion({
          key: String(habitacion.id),
          data: { producto, cantidad },
        })
      );
    }
  };

  const deleteHandler = (uuid: string) => {
    if (isEditable === true) {
      // const uuids = selectedRows.map(({ uuid }: ProductApiObject) => uuid);
      dispatch(
        deleteProductoToHabitacion({
          key: String(habitacion.id),
          data: uuid,
        })
      );
    }
  };

  const columns: any = [
    {
      cell: (row: ProductApiObject) => (
        <div
          className="bg-cover bg-center w-24 h-24 rounded-xl my-2 cursor-pointer"
          onClick={() => seeProductDetails && seeProductDetails(row)}
          style={{
            backgroundImage: `url('${row.imagen_url || "/no-image.png"}')`,
          }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: false,
    },
    {
      name: "Código",
      selector: (row: ProductApiObject) => row.codigo,
    },
    {
      name: "Nombre",
      selector: (row: ProductApiObject) => row.nombre_completo,
      sortable: true,
    },
    {
      name: "Tipo producto",
      selector: (row: ProductApiObject) => row.tipo_producto,
      sortable: true,
    },
    {
      name: "P. Unitario",
      selector: (row: ProductApiObject) => row.precio,
      format: (row: ProductApiObject) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
        }).format(row.precio),
      sortable: true,
    },
    {
      cell: (row: ProductApiObject) =>
        isEditable === true && (
          <div className="flex items-center justify-center">
            <InputCounter
              row={row}
              handleChange={handleChange}
              storeValue={find(localModel, ["producto.uuid", row.uuid])}
            />
          </div>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row: ProductApiObject) =>
        find(localModel, ["producto.uuid", row.uuid]) && (
          <>
            {isEditable === true && (
              <button
                onClick={() => deleteHandler(row.uuid)}
                className="flex items-center"
                type="button"
              >
                <div className="w-6 h-6 text-white bg-red-600 flex items-center justify-center rounded-full mr-2">
                  ✖
                </div>
              </button>
            )}
          </>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const DataForTable =
    overallData.length > 0 ? overallData : data?.productos ?? [];

  return (
    <div className="p-4">
      <div className="flex my-3">
        <h3 className="flex-1 text-xl font-semibold">
          Productos por habitación
        </h3>
        {!preValues && (
          <div className="flex-1 flex flex-col my-4">
            <label>Filtrar por tipo de producto</label>
            <MultiSelect
              options={tiposFilters}
              value={filtros}
              onChange={setFiltros}
              labelledBy="Estílo"
              className="border rounded-full border-gray-100 px-4 bg-white min-h-12  focus:outline-none pt-1"
            />
          </div>
        )}
      </div>
      <DataTable
        columns={columns}
        data={DataForTable}
        pagination
        progressPending={isLoading || isFetching}
      />
    </div>
  );
};

export default memo(HabitacionTab);
