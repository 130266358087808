import ProductApiObject from "../store/dto/producto.dto";

interface Props {
  closeModal: () => void;
  openProd?: ProductApiObject;
}
const ModalDetailProduct = ({ closeModal, openProd }: Props) => {
  return (
    <>
      <div className="flex flex-row items-center">
        <h2 className="mr-auto text-2xl">Detalle producto:</h2>
        <button onClick={closeModal}>
          <strong className="text-xl align-center cursor-pointer alert-del">
            &times;
          </strong>
        </button>
      </div>
      <section className="text-gray-700 body-font overflow-hidden bg-white">
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-4/5 mx-auto flex flex-wrap">
            <img
              alt="ecommerce"
              className="lg:w-1/2 w-full object-cover object-center rounded border border-gray-200"
              src={
                openProd?.imagen_url ||
                "https://morhada-api.s3.us-east-2.amazonaws.com/no-image.png"
              }
            />
            <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
              <div className="flex flex-row">
                <h2 className="text-sm title-font text-gray-500 tracking-widest mr-2">
                  {openProd?.codigo}
                </h2>
                <div className="inline-block relative text-xs">
                  <div className="absolute inset-0 text-blue-300 flex">
                    <svg height="100%" viewBox="0 0 50 100">
                      <path
                        d="M49.9,0a17.1,17.1,0,0,0-12,5L5,37.9A17,17,0,0,0,5,62L37.9,94.9a17.1,17.1,0,0,0,12,5ZM25.4,59.4a9.5,9.5,0,1,1,9.5-9.5A9.5,9.5,0,0,1,25.4,59.4Z"
                        fill="currentColor"
                      />
                    </svg>
                    <div className="flex-grow h-full -ml-px bg-blue-300 rounded-md rounded-l-none"></div>
                  </div>
                  <span className="relative text-blue-500 uppercase font-semibold pr-px">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    {openProd?.uso_exterior === true
                      ? "Uso Exterior"
                      : "Uso Interior"}
                    <span>&nbsp;</span>
                  </span>
                </div>
              </div>
              <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                {openProd?.nombre_completo}
              </h1>
              <p className="leading-relaxed">{openProd?.descripcion}</p>
              {openProd?.familia_inmueble?.nombre && (
                <div className="flex items-center mb-1">
                  <div className="flex">
                    <span className="mr-2">Especial para:</span>
                    {openProd?.familia_inmueble?.nombre}
                  </div>
                </div>
              )}
              {openProd?.material && (
                <div className="flex items-center mb-1">
                  <div className="flex">
                    <span className="mr-2">Material:</span>
                    {openProd?.material}
                  </div>
                </div>
              )}
              {openProd?.acabado && (
                <div className="flex items-center mb-1">
                  <div className="flex">
                    <span className="mr-2">Acabado:</span>
                    {openProd?.acabado}
                  </div>
                </div>
              )}
              {openProd?.tela && (
                <div className="flex items-center mb-1">
                  <div className="flex">
                    <span className="mr-2">Tela:</span>
                    {openProd?.tela}
                  </div>
                </div>
              )}
              <p className="leading-relaxed">{openProd?.descripcion}</p>
              {openProd?.color && (
                <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
                  <div className="flex">
                    <span className="mr-2">Color(es)</span>
                    {openProd?.color}
                  </div>
                </div>
              )}
              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  {openProd?.precio
                    ? new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(openProd?.precio)
                    : "$ 0"}
                </span>
              </div>
              <div className="flex flex-col mt-4">
                <h3 className="text-normal tracking-widest">
                  Medidas -<small>{openProd?.unidad_medida}</small>-
                </h3>
                <ul>
                  {openProd?.largo_1 && (
                    <li>
                      Largo - A: <strong>{openProd?.largo_1}</strong>
                    </li>
                  )}
                  {openProd?.largo_2 && (
                    <li>
                      Largo - B: <strong>{openProd?.largo_2}</strong>
                    </li>
                  )}
                  {openProd?.ancho_2 && (
                    <li>
                      Ancho - A: <strong>{openProd?.ancho_1}</strong>
                    </li>
                  )}
                  {openProd?.ancho_2 && (
                    <li>
                      Ancho - B: <strong>{openProd?.ancho_2}</strong>
                    </li>
                  )}
                  {openProd?.alto_2 && (
                    <li>
                      Alto - A: <strong>{openProd?.alto_1}</strong>
                    </li>
                  )}
                  {openProd?.alto_2 && (
                    <li>
                      Alto - B: <strong>{openProd?.alto_2}</strong>
                    </li>
                  )}
                  {openProd?.diametro_2 && (
                    <li>
                      Diametro - A: <strong>{openProd?.diametro_1}</strong>
                    </li>
                  )}
                  {openProd?.diametro_2 && (
                    <li>
                      Diametro - B: <strong>{openProd?.diametro_2}</strong>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ModalDetailProduct;
