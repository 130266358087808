import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import SearchInput from "../../components/SearchInput";
import ProductCard from "../../components/ProdutCard";

import { buildings, update } from "../../assets/icons/index";
import {
  useLazyGetDesarrollosQuery,
  useUpdateModelOfDevelopmentsMutation,
} from "../../store/morhanaApi";
import InputEdition from "../../components/InputEdition";
import { imageValidator } from "../../constans/utils";

const Espacios: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [overallData, setoverallData] = useState<any[]>([]);

  const [fettcher, { data, isLoading, isFetching }] =
    useLazyGetDesarrollosQuery();
  const [setter, { isError, isSuccess }] =
    useUpdateModelOfDevelopmentsMutation();

  const onSaveHandler = (id: number, value: any) => {
    setter({ type: "desarrollos", id, value: { nombre_desarrollo: value } });
  };

  const searchClicked = () => {
    if (data) {
      const filteredItems = data?.filter((item) =>
        item.nombre_desarrollo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setoverallData([...(filteredItems ?? [])]);
    }
  };

  useEffect(() => {
    fettcher("").unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error("Ocurrió un error al querer editar los datos.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Se editaron los datos con éxito", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess]);

  const DataForTable = overallData.length > 0 ? overallData : data ?? [];

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <ToastContainer />
      <div className="flex">
        <h1 className="mb-6 text-xl mr-auto font-bold">
          Solucionador de espacios
        </h1>
      </div>
      <div className="mt-8 flex md:flex-row flex-col bg-white rounded-lg p-4 items-center">
        <div>
          <button
            title="Actualizar"
            type="button"
            className="w-12 h-12 rounded-full bg-white border mr-8"
            onClick={() => fettcher("").unwrap()}
          >
            <div className="flex items-center justify-center">
              {isLoading || isFetching ? (
                <div
                  className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                />
              ) : (
                <img alt="update Icon" src={update} className="w-6 mx-auto" />
              )}
            </div>
          </button>
        </div>
        <div className="flex flex-col mr-auto">
          <label>Buscar desarrollo existente</label>
          <SearchInput
            getSearchText={setSearchTerm}
            buttonClicked={searchClicked}
          />
        </div>
        <Link to="/espacios/nuevo" className="custom-btn bg-primary text-white">
          Cargar nuevo desarrollo
        </Link>
      </div>
      <div className="mt-8 bg-white rounded-lg p-4 items-center">
        <h2 className="text-xl font-semibold">Desarrollos</h2>
        {isLoading || isFetching ? (
          <div className="flex items-center justify-center my-8">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <div className="mt-8 grid md:grid-cols-4 grid-cols-2 grid-flow-row gap-4">
            {DataForTable.map((el: any, i: number) => (
              <ProductCard
                imgSource={imageValidator(el.plano_url)}
                key={`model_${i}`}
              >
                <div className="flex flex-col">
                  <InputEdition
                    value={el.nombre_desarrollo}
                    cssAdditional="mb-2 text-xl font-semibold text-center"
                    onSave={(value: any) => onSaveHandler(el.id, value)}
                  />
                  <div className="flex flex-row justify-center items-center my-6 mx-4">
                    <div className="flex flex-row justify-center items-center">
                      <img
                        src={buildings}
                        alt="cinta metrica"
                        className="w-8"
                      />
                      <span className="ml-2">
                        Prototipos: {el.fk_prototipos.length}
                      </span>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 grid-cols-1 grid-flow-row gap-1 w-full">
                    {el.fk_prototipos.map((el: any) => (
                      <Link
                        key={`link-${el.id}`}
                        to={`/espacios/espacio?id=${el.id}`}
                        className="bg-gray-200 text-center rounded-md p-1 text-xs"
                      >
                        {el.nombre_prototipo}
                      </Link>
                    ))}
                  </div>
                </div>
              </ProductCard>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Espacios;
