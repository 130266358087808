import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cloneDeep, find, get, isNumber, isString, orderBy } from "lodash";
import { ToastContainer, toast } from "react-toastify";

import SchemaForm from "../../components/FormsJson/SchemaForm";
import SchemaSelect from "../../components/FormsJson/SchemaSelect";
import CheckIcon from "../../components/CheckIcon";
import { ModelSetterForRoom } from "../../components/FormsJson/types.dto";
import Button from "../../components/Button";
import { TypesButton } from "../../components/types.dto";
import InputMoney from "../../components/InputMoney";

import {
  addDataToModel,
  addDataToModelObject,
  getProjectModel,
  setDataSpaceToModel,
  setDataToChildrenNode,
  deleteDataFromChildren,
  addNewColor,
  removeColor,
  resetToInitialState,
  setNewTypeRoomOfMedidas,
  deleteDataSpacetoModel,
} from "../../store/projectSlice";
import { ProjectState } from "../../store/dto/project.dto";
import { ApiDesarrolloData } from "../../store/dto/espacio.dto";
import {
  useGetFamiliasInmuebleQuery,
  useLazyGetDesarrollosQuery,
  useSetNewProyectoMutation,
} from "../../store/morhanaApi";
import Api from "../../constans/api";
import { ASISTENCIAS, todayDay, DROPLIST } from "../../constans/utils";

import estadoCiudad from "../../assets/json/estado_ciudad.json";
import datoscliente from "../../assets/json/proyectos/datoscliente.json";
import presupuesto from "../../assets/json/proyectos/presupuesto.json";
import necesidades from "../../assets/json/proyectos/necesidades.json";
import necesidadesOther from "../../assets/json/proyectos/necesidades_other.json";
import extras from "../../assets/json/proyectos/extras.json";
import extra_project from "../../assets/json/proyectos/extra_project.json";
import dimensions from "../../assets/json/proyectos/dimensions.json";
import SchemaColor from "../../components/FormsJson/SchemaColor";
import ChildrenArray from "../../components/Proyecto/ChildrenArray";
import FormRoomCardSpace from "../../components/FormRoomCardSpace";

interface ObjectType {
  [key: string]: Array<string>;
}
interface ObjectEnum {
  label: string;
  value: number | string;
}

const HIJOS_SCHEME = { genero: "", edad: "" };

const ProyectosNew: React.FC = () => {
  const [selectValue, setSelectValue] = useState("");
  const [showMedidas, setShowMedidas] = useState("");
  const [selectDesarrollo, setSelectDesarrollo] = useState("");
  const [jsonDatosClientes, setJsonDatosClientes] = useState(datoscliente);
  const [jsonNecesidades, setJsonNecesidades] = useState(necesidades);

  const localModel: ProjectState = useSelector(getProjectModel);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendnewProyect, { data, isSuccess, isLoading }] =
    useSetNewProyectoMutation();
  const [desarrollosFetcher, { data: dataDesarrollos }] =
    useLazyGetDesarrollosQuery();
  const { data: dataFamilias } = useGetFamiliasInmuebleQuery(null);

  const estadosMapped = useMemo(() => {
    return Object.keys(estadoCiudad).map((estado: string) => {
      return { label: estado, value: estado };
    });
  }, []);
  const api = useMemo(() => new Api(), []);

  const dataSetterHandler = useCallback(
    (key: string, inputValue: String) => {
      dispatch(
        addDataToModelObject({
          object: "cliente",
          data: {
            [key]: key === "presupuesto" ? inputValue === "true" : inputValue,
          },
        })
      );
    },
    [dispatch]
  );

  const colorHandler = useCallback(
    (_key: string, inputValue: any) => {
      if (isString(inputValue)) {
        dispatch(addNewColor(inputValue));
      } else if (isNumber(inputValue)) {
        dispatch(removeColor(inputValue));
      }
    },
    [dispatch]
  );

  const dataNecesidadesHandler = useCallback(
    (key: string, inputValue: any) => {
      if (key === "expectativa_color") {
        colorHandler(key, inputValue);
      } else {
        dispatch(
          addDataToModelObject({
            object: "necesidades",
            data: {
              [key]: key === "mascota" ? inputValue === "true" : inputValue,
            },
          })
        );
      }
      if (key === "razon") {
        const enums = get(DROPLIST, `[${inputValue}]`, []);
        const json: any = cloneDeep(jsonNecesidades);
        json.properties.quien.enum = enums;
        setJsonNecesidades(json);
      }
    },
    [colorHandler, dispatch, jsonNecesidades]
  );

  const checkAsistanceHandler = useCallback(
    (key: string, inputValue: Boolean) => {
      dispatch(
        addDataToModelObject({
          object: "asistencias",
          data: { [key]: inputValue },
        })
      );
    },
    [dispatch]
  );

  const checkSwitchExtrasHandler = useCallback(
    (key: string, inputValue: Boolean) => {
      dispatch(
        addDataToModelObject({
          object: "extras",
          data: { [key]: inputValue },
        })
      );
    },
    [dispatch]
  );

  const promiseOptionsHandler = async (inputValue: string) => {
    if (inputValue !== "") {
      try {
        const res = await api.http.get(`clientes/${inputValue}`);
        if (res && Array.isArray(res)) {
          const mapped: Array<ObjectEnum> = res.map((client) => {
            return {
              value: client.nombre,
              label: client.nombre,
            };
          });
          return mapped;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const addingNewOptionToSelect2 = (value: string) => {
    const newEnum = [{ label: value, value }];
    const json: any = cloneDeep(jsonDatosClientes);
    json.properties.cliente.enum = newEnum;
    setJsonDatosClientes(json);
    dataSetterHandler("cliente", value);
    // USERKEYS.forEach((key) => dataSetterHandler(key, ""));
  };

  useEffect(() => {
    desarrollosFetcher("").unwrap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localModel.uuid) {
      dispatch(resetToInitialState());
    }
  }, [dispatch, localModel.uuid]);

  useEffect(() => {
    if (localModel.cliente.cliente) {
      const fetchClient = async () => {
        const [client] = await api.http.get(
          `clientes/${localModel.cliente.cliente}`
        );
        if (client && client?.id) {
          Object.keys(client).forEach((key: string) => {
            if (key !== "id" && key !== "nombre") {
              dataSetterHandler(key, client[key]);
            }
          });
        }
      };
      fetchClient();
    }
  }, [api, dataSetterHandler, localModel.cliente.cliente]);

  useEffect(() => {
    if (isSuccess && data?.uuid) {
      dispatch(resetToInitialState());
      navigate(`/proyecto/detalle?uuid=${data.uuid}`);
    }
  }, [isSuccess, navigate, data, dispatch, localModel.plano]);

  useEffect(() => {
    if (
      localModel.cliente.cliente === "" &&
      jsonDatosClientes.properties.cliente.widget.props.loadOptions === ""
    ) {
      const json: any = cloneDeep(jsonDatosClientes);
      json.properties.cliente.widget.props.loadOptions = promiseOptionsHandler;
      setJsonDatosClientes(json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jsonDatosClientes.properties.cliente.widget.props.loadOptions,
    localModel.cliente.cliente,
    promiseOptionsHandler,
  ]);

  useEffect(() => {
    if (
      localModel.cliente.cliente &&
      jsonDatosClientes.properties.cliente.widget.props.onCreateOption === ""
    ) {
      const json: any = cloneDeep(jsonDatosClientes);
      json.properties.cliente.widget.props.onCreateOption =
        addingNewOptionToSelect2;
      setJsonDatosClientes(json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addingNewOptionToSelect2,
    jsonDatosClientes.properties.cliente.widget.props.onCreateOption,
    localModel.cliente.cliente,
  ]);

  useEffect(() => {
    if (
      estadosMapped.length > 0 &&
      jsonDatosClientes.properties.estado.enum.length === 0
    ) {
      const json: any = cloneDeep(datoscliente);
      json.properties.estado.enum = estadosMapped;
      setJsonDatosClientes(json);
    }
  }, [estadosMapped, jsonDatosClientes]);

  useEffect(() => {
    if (localModel.cliente.estado) {
      const founded = Object.keys(estadoCiudad).find(
        (estado: string) => estado === localModel.cliente.estado
      );
      if (founded) {
        const objectCities: ObjectType = cloneDeep(estadoCiudad);
        const mapped = objectCities[founded as keyof ObjectType].map(
          (estado: string) => {
            return { label: estado, value: estado };
          }
        );
        const json: any = cloneDeep(datoscliente);
        json.properties.estado.enum = estadosMapped;
        json.properties.ciudad.enum = mapped;
        setJsonDatosClientes(json);
        dataSetterHandler("ciudad", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadosMapped, localModel.cliente.estado]);

  const FormRoomCardSpaceHandler = ({
    index,
    value,
    key,
  }: ModelSetterForRoom) => {
    dispatch(
      setDataSpaceToModel({
        index,
        key,
        data: value,
      })
    );
  };

  const addingNewRoomToObject = () => {
    if (!selectValue) {
      return toast.error(
        "Seleccione primero el tipo de familia al que pertenece"
      );
    } else if (Object.keys(localModel.medidas_espacios).includes(selectValue)) {
      return toast.warning(
        "Ya existe esa familia dentro de las habitaciones agregadas."
      );
    }
    dispatch(setNewTypeRoomOfMedidas(selectValue));
    setSelectValue("");
  };

  const deleteObjectModelToSpace = (index: number) => {
    dispatch(deleteDataSpacetoModel({ index }));
  };

  const hijosHandler = (key: any, value: String | any, i: number) => {
    dispatch(setDataToChildrenNode({ key, data: value, index: i }));
  };

  const newChildrenInArray = () => {
    const arr = cloneDeep(localModel.necesidades.hijos);
    arr.push(HIJOS_SCHEME);
    dataNecesidadesHandler("hijos", arr);
  };

  const deleteElementofArray = (i: number) => {
    dispatch(deleteDataFromChildren(i));
  };

  const medidasDesarrolloHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    dispatch(
      addDataToModel({ key: "medidas_espacio_id", data: e.target.value })
    );
    setSelectDesarrollo(e.target.value);
  };

  const viewDesarrollosHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowMedidas(e.target.value);
    dispatch(
      addDataToModel({
        key: "medidas_espacio_id",
        data: "",
      })
    );
  };

  return (
    <div className="lg:p-8 md:p-4 p-2">
      <ToastContainer />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await sendnewProyect(localModel).unwrap();
        }}
      >
        <div className="flex flex-row items-center mb-4">
          <h1 className="mb-6 text-xl mr-auto font-bold">Nuevo proyecto</h1>
          <div className="ml-auto mr-2">Fecha de registro</div>
          <div className="custom-btn bg-white text-gray-900">{todayDay}</div>
        </div>
        <section className="bg-white px-6 py-4 rounded-lg">
          <SchemaForm
            schema={jsonDatosClientes}
            customModelSetter={dataSetterHandler}
            customModelValues={localModel.cliente}
          />
        </section>
        <section className="bg-white px-6 py-4 rounded-lg mt-8">
          <div className="grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4">
            <SchemaSelect
              schema={presupuesto}
              nameForm={"presupuesto"}
              modelSetter={dataSetterHandler}
              value={localModel.cliente.presupuesto}
            />
          </div>
          {localModel.cliente.presupuesto !== "" &&
            !localModel.cliente.presupuesto && (
              <>
                <SchemaForm
                  schema={jsonNecesidades}
                  classNames="grid lg:grid-cols-2 grid-cols-1 grid-flow-row gap-4"
                  customModelSetter={dataNecesidadesHandler}
                  customModelValues={localModel.necesidades}
                />
                {localModel.necesidades.quien === "con_hijos" && (
                  <div className="p-4 flex flex-col bg-gray-100 rounded-lg mx-2 my-6">
                    <h4 className="font-semibold">Hijos: </h4>
                    <ChildrenArray
                      customModelValues={localModel.necesidades.hijos}
                      customModelSetter={hijosHandler}
                      newElementToArray={newChildrenInArray}
                      deleteElementofArray={deleteElementofArray}
                    />
                  </div>
                )}
                <SchemaForm
                  schema={necesidadesOther}
                  classNames="grid lg:grid-cols-2 grid-cols-1 grid-flow-row gap-4"
                  customModelSetter={dataNecesidadesHandler}
                  customModelValues={localModel.necesidades}
                />
                <div className="my-4">
                  <SchemaColor
                    schema={{
                      title: "Expectativa del color",
                      type: "string",
                      widget: {
                        type: "SchemaColor",
                        props: {
                          required: true,
                        },
                      },
                    }}
                    value={localModel.necesidades.expectativa_color}
                    nameForm="expectativa_color"
                    modelSetter={dataNecesidadesHandler}
                  />
                </div>
                <div className="flex xl:flex-row flex-col">
                  <div className="xl:ml-0 m-2 flex flex-col flex-1">
                    <h3 className="text-lg">Requieres asistencia especial</h3>
                    <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-flow-row gap-4">
                      {ASISTENCIAS.map((asistencia, i) => (
                        <CheckIcon
                          key={`check_icon_${i}`}
                          name={asistencia.name}
                          label={asistencia.label}
                          icon={asistencia.icon}
                          // @ts-ignore
                          checked={localModel.asistencias[asistencia.name]}
                          modelSetter={checkAsistanceHandler}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="xl:mr-0 m-2 flex-1">
                    <SchemaForm
                      classNames="grid xl:grid-cols-2 md:grid-cols-1 grid-cols-1 grid-flow-row gap-4"
                      schema={extras}
                      // @ts-ignore
                      customModelSetter={checkSwitchExtrasHandler}
                      customModelValues={localModel.extras}
                    />
                  </div>
                </div>
                <div className="mt-8">
                  <SchemaForm
                    schema={extra_project}
                    // @ts-ignore
                    customModelSetter={checkSwitchExtrasHandler}
                    customModelValues={localModel.extras}
                  />
                </div>
              </>
            )}
          {localModel.cliente.presupuesto && (
            <>
              <p>
                ⓘ Recuerda que al indicar que si existe un presupuesto,
                ingresarlo y click en "Aceptar" se te redireccionará para la
                selección de productos
              </p>
              <div className="grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4">
                <InputMoney
                  value={localModel.budget}
                  placehoder="Min"
                  otherProps={{ required: "required" }}
                  getValueFromInput={(value: string) =>
                    dispatch(addDataToModel({ key: "budget", data: value }))
                  }
                />
              </div>
            </>
          )}
          {localModel.cliente.presupuesto !== "" && (
            <>
              <article>
                <h4 className="font-semibold text-normal">
                  Opciones de medidas
                </h4>
                <div className="grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4">
                  <div className="flex flex-col">
                    <select
                      id="adjuntar_plano"
                      placeholder="tengo medidas/Buscar Desarrollo"
                      className="input-style"
                      value={showMedidas}
                      defaultValue=""
                      onChange={viewDesarrollosHandler}
                    >
                      <option disabled value="">
                        Tengo medidas/Buscar Desarrollo
                      </option>
                      <option value="medidas">Tengo medidas</option>
                      <option value="desarrollo">Buscar desarrollo</option>
                    </select>
                  </div>
                  {showMedidas === "desarrollo" && (
                    <>
                      <div className="flex flex-col">
                        <select
                          id="desarrollo_selecter"
                          placeholder="Seleccionar dearrollo"
                          className="input-style"
                          value={selectDesarrollo}
                          defaultValue={""}
                          onChange={medidasDesarrolloHandler}
                        >
                          <option disabled value="">
                            Seleccionar...
                          </option>
                          {(dataDesarrollos ?? [])
                            .filter(
                              (de: ApiDesarrolloData) =>
                                de.fk_prototipos.length > 0
                            )
                            .map((de: ApiDesarrolloData, i: number) => (
                              <optgroup
                                key={`opt_group_${i}_${de.nombre_desarrollo}`}
                                label={`Desarrollo - ${de.nombre_desarrollo}`}
                              >
                                {de.fk_prototipos.map((prototipo: any) => (
                                  <option
                                    key={`opt_value_${de.nombre_desarrollo}_${i}_${prototipo.id}`}
                                    value={prototipo.id}
                                  >
                                    {prototipo.nombre_prototipo}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                        </select>
                      </div>
                    </>
                  )}
                </div>
                {showMedidas === "medidas" && (
                  <div className="flex flex-col">
                    <div className="my-4 flex items-center mx-auto">
                      <div className="flex flex-col">
                        <label htmlFor={`familias`}>
                          Seleccione la familia a la que pertenece
                        </label>
                        <select
                          id={`familias`}
                          value={selectValue}
                          className="input-style"
                          defaultValue={""}
                          onChange={(e: any) => setSelectValue(e.target.value)}
                        >
                          <option disabled value={""}>
                            Seleccione...
                          </option>
                          {(dataFamilias
                            ? orderBy(
                                dataFamilias,
                                [
                                  (o) => o.nombre.includes("Sala"),
                                  (o) => o.nombre.includes("Comedor"),
                                  "nombre",
                                ],
                                ["desc", "desc", "asc"]
                              )
                            : []
                          ).map(({ id, nombre }) => (
                            <option key={`familias_${id}`} value={id}>
                              {nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Button
                        text="Agregar"
                        type={TypesButton.Button}
                        classes="ml-4 bg-secondary text-white"
                        onClick={addingNewRoomToObject}
                      />
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 grid-flow-row gap-4 w-full">
                      {localModel.medidas_espacios.map(
                        ({ id_familia_inmueble }, i: number) => (
                          <FormRoomCardSpace
                            key={`form_room_card_space_${i}`}
                            keyIndex={i}
                            familiaName={
                              id_familia_inmueble
                                ? find(dataFamilias, [
                                    "id",
                                    Number(id_familia_inmueble),
                                  ])?.nombre
                                : ""
                            }
                            schemaObject={dimensions}
                            customModelValues={localModel.medidas_espacios[i]}
                            customModelSetter={FormRoomCardSpaceHandler}
                            deleteModel={deleteObjectModelToSpace}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
              </article>
              <div className="text-center mt-8 mb-4">
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div
                      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] mr-4"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                    <span className="text-uppercase">
                      {localModel.budget === 0
                        ? "Enviando información y generando imágenes IA... por favor espere."
                        : "Enviando información... por favor espere."}
                    </span>
                  </div>
                ) : (
                  <Button
                    text="Aceptar"
                    type={TypesButton.Submit}
                    classes="bg-primary text-white"
                  />
                )}
              </div>
            </>
          )}
        </section>
      </form>
    </div>
  );
};

export default ProyectosNew;
