interface CheckIconProps {
  label: string;
  icon: string;
  name: string;
  checked: Boolean;
  modelSetter: (name: string, value: Boolean) => void;
}

const CheckIcon = ({
  name,
  label,
  icon,
  checked,
  modelSetter,
}: CheckIconProps) => {
  const checkHandler = (name: string, value: Boolean) => {
    modelSetter(name, value);
  };

  return (
    <label
      htmlFor={`check_${name}`}
      className={
        checked
          ? "border-none bg-primary text-white w-92 h-92 flex flex-col p-2 rounded cursor-pointer w-32"
          : "p-2 border border-gray-300 w-92 h-92 bg-white flex flex-col rounded cursor-pointer w-32"
      }
    >
      <p>{label}</p>
      <img alt={label} className="mt-8 w-8" src={icon} />
      <input
        id={`check_${name}`}
        hidden
        type="checkbox"
        name={`check_${name}`}
        onChange={(e) => checkHandler(name, e.target.checked)}
      />
    </label>
  );
};

export default CheckIcon;
