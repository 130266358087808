import { useState } from "react";

interface InputEditionProps {
  value: any;
  cssAdditional?: string;
  onSave: (localValue: any) => void;
}
const InputEdition = ({ value, cssAdditional, onSave }: InputEditionProps) => {
  const [localValue, setLocalValue] = useState(value || "");
  const [show, setShow] = useState(false);

  return (
    <div className="flex flex-col">
      <input
        onClick={() => {
          setShow(true);
        }}
        onInput={(e: any) => setLocalValue(e.target.value)}
        value={localValue}
        // onFocusCapture={}
        className={`appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none ${cssAdditional}`}
      />
      {show && (
        <div className="flex flex-row">
          <button
            onClick={() => {
              setShow(false);
              onSave && onSave(localValue);
            }}
            type="button"
            className="flex-1 text-green-600"
          >
            Guardar
          </button>
          <button
            type="button"
            className="flex-1 text-red-600"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancelar
          </button>
        </div>
      )}
    </div>
  );
};

export default InputEdition;
